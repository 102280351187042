import React, { useCallback, useEffect, useState } from 'react'
import { IMarker } from '../../interfaces/snp.interface'
import { useTranslation } from 'react-i18next'
import { getGeneralInfo } from '../../requests/snp.request';
import { SnpInfoWrapper } from './index.styles';
import { Building, Employment, Population, Road } from '../../assets/icons';
import { Text } from '../text.component';
import { checkNonEmpty, checkVal } from '../../utils/helpers.utils';

const SnpInfo: React.FC<{ selectedMarker: IMarker | null }> = ({ selectedMarker }) => {
  const { t } = useTranslation();
  const [info, setInfo] = useState<any>();

  const getInfo = useCallback(() => {
    selectedMarker && getGeneralInfo(selectedMarker?.code).then((data) => {
      const { population, employment, buildingNumber, regionRoad, areaRoad } = data;
      setInfo({ population, employment, buildingNumber, regionRoad, areaRoad });
    })
  }, [selectedMarker])

  useEffect(() => {
    getInfo();
  }, [getInfo, selectedMarker])

  if (!selectedMarker) {
    return <h1>{t('no-data')}</h1>
  }

  return (
    <SnpInfoWrapper>
      <div className="container">
        <div className="population-all">
          <div style={{ textTransform: 'uppercase' }}>
            <Population />
            <Text fontSize='1.0625rem' fontWeight={700} color='#118F84'>{t('form.population')}</Text>
          </div>
          <div>
            <Text fontSize='2.1875rem' fontWeight={600} color='#118F84'>{info?.population.all}</Text>
            <Text fontSize='0.875rem' fontWeight={400} color='#606367'>{t('form.people')}</Text>
          </div>
        </div>
        <div className="population-grid">
          {
            info?.population && Object.keys(info.population).some(key => key !== 'all' && checkVal(info.population[key])) &&
            <div className="grid-row">
              {info?.population && Object.keys(info?.population) && Object.keys(info?.population).filter(key => key !== 'all' && checkVal(info.population[key])).map((key) => (
                <div className='grid-item'>
                  <div className="header">
                    <Text fontSize='0.875rem' fontWeight={700} color='#118F84'>{t(`form.populationObj.${key}`)}</Text>
                  </div>
                  <div className="value">
                    <Text fontSize='1.5625rem' fontWeight={500} color='#606367'>{info?.population[key as keyof typeof info.population].toLocaleString('ru-RU')}</Text>
                    <Text fontSize='0.875rem' fontWeight={400} color='#606367'>{t('form.people')}</Text>
                  </div>
                </div>
              ))}
            </div>
          }

          {
            info?.employment && Object.keys(info.employment).some(key => checkVal(info.employment[key])) &&
            <div className="grid-row">
              {info?.employment && Object.keys(info?.employment) && Object.keys(info?.employment).filter(key => checkVal(info.employment[key])).map((key) => (
                <div className='grid-item'>
                  <div className="header">
                    <Employment /> <Text fontSize='0.875rem' fontWeight={700} color='#2366CA'>{t(`form.employmentObj.${key}`)}</Text>
                  </div>
                  <div className="value">
                    <Text fontSize='1.5625rem' fontWeight={500} color='#2366CA'>{info?.employment[key as keyof typeof info.employment].toLocaleString('ru-RU')}</Text>
                    <Text fontSize='0.875rem' fontWeight={400} color='#606367'>{t('form.people')}</Text>
                  </div>
                </div>
              ))}
            </div>
          }
        </div>
      </div>

      {(checkVal(info?.buildingNumber)
        || (checkVal(info?.regionRoad) && Object.values(info?.regionRoad).some(item => checkVal(item)))
        || (checkVal(info?.areaRoad && Object.values(info?.regionRoad).some(item => checkVal(item)))))
        && <div className="container yellow">
          <div className="grid-row">
            {
              checkVal(info?.buildingNumber) &&
              <div className='grid-item'>
                <div className="header">
                  <Building /> <Text fontSize='0.875rem' fontWeight={700} color='#118F84'>{t(`living`)}</Text>
                </div>
                <div className="value">
                  <Text fontSize='1.5625rem' fontWeight={600} color='#118F84'>{info?.buildingNumber?.toLocaleString('ru-RU')}</Text>
                  <Text fontSize='0.875rem' fontWeight={400} color='#606367'>{t('snp-houses')}</Text>
                </div>
              </div>
            }

            {checkVal(info?.regionRoad) && checkNonEmpty(info?.regionRoad) && Object.values(info?.regionRoad).some(item => checkVal(item)) && <div className='grid-item'>
              <div className="header">
                <Road /> <Text fontSize='0.875rem' fontWeight={700} color='#118F84'>{t(`form.roadsTitle`)}</Text>
              </div>
              <div className="value">
                <Text fontSize='1.5625rem' fontWeight={600} color='#118F84'>{info?.regionRoad?.distance?.toLocaleString('ru-RU')}</Text>
                <Text fontSize='0.875rem' fontWeight={400} color='#606367'>
                  км {t(`form.regionRoad.title`)}{', '}
                  {info[`regionRoad`]?.quality && `${t('snp-condition')} ${t(`form.roads.${info[`regionRoad`].quality}`).toLowerCase()}, `}
                  {info[`regionRoad`]?.cover && t(`form.roads.${info[`regionRoad`].cover}`).toLowerCase()}
                </Text>
              </div>
            </div>}

            {checkVal(info?.areaRoad) && checkNonEmpty(info?.areaRoad) && Object.values(info?.areaRoad).some(item => checkVal(item)) && <div className='grid-item'>
              <div className="header">
                <Road /> <Text fontSize='0.875rem' fontWeight={700} color='#118F84'>{t(`form.roadsTitle`)}</Text>
              </div>
              <div className="value">
                <Text fontSize='1.5625rem' fontWeight={600} color='#118F84'>{info?.areaRoad?.distance?.toLocaleString('ru-RU')}</Text>
                <Text fontSize='0.875rem' fontWeight={400} color='#606367'>
                  км {t(`form.areaRoad.title`)}{', '}
                  {info[`areaRoad`]?.quality && `${t('snp-condition')} ${t(`form.roads.${info[`areaRoad`].quality}`).toLowerCase()}, `}
                  {info[`areaRoad`]?.cover && t(`form.roads.${info[`areaRoad`].cover}`).toLowerCase()}
                </Text>
              </div>
            </div>}
          </div>
        </div>}
    </SnpInfoWrapper >
  )
}

export default SnpInfo