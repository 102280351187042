import React, { ChangeEvent } from 'react'
import { Field } from 'formik';
import { FooterButton, FormGroup } from '../admin-page/admin-page.styles';
import { FormBlock, FormBlockButton } from '../insfrastructureProjects/infrastructure.styles'
import { AddField } from '../../assets/icons';
import { lowerAndTrim, t } from '../../utils/helpers.utils'
import { ITempVal } from '../insfrastructureProjects/education-form.component';

interface IProps {
  formData: any;
  setFormData: (formData: any) => void;
  setFieldValue: (field: string, value: any) => void;
  lang: 'Ru' | 'Kz';
  onSave: (data: any) => void;
  setTempVal: (data: any) => void;
  tempVal: ITempVal;
  onAdd: () => void;
  onTextAdd: () => void
  onDelete: (key: string) => void;
  type: string;
  errors: any;
}

const AdditionalFieldsForm: React.FC<IProps> = ({ formData, setFormData, setFieldValue, lang, setTempVal, tempVal, onDelete, onAdd, onTextAdd, type, errors }) => {

  const handleCancelAdd = () => {
    setTempVal({ isAdding: false, labelKz: '', labelRu: '', valueKz: '', valueRu: '', isAddingText: false, textRu: '', textKz: '', labelError: false, valueError: false, textError: false });
  }

  return (
    <FormBlock>
      <div className="title">{t(`form.additionalFields`, lang)}</div>

      {formData.additionalFields && Object.keys(formData.additionalFields).length > 0 && Object.keys(formData.additionalFields).filter((key) => !['ru', 'kz'].includes(key)).map((key) => {
        return (
          <div className="row sb" key={key}>
            <FormGroup>
              {!key.includes('no-label') && <label htmlFor={`additionalFields.${key}.label${lang}`}>{formData.additionalFields[key][`label${lang}`]}</label>}
              <Field
                id={`additionalFields.${key}`}
                name={`additionalFields.${key}`}
                value={formData.additionalFields[key][`${key.includes('no-label') ? 'text' : 'value'}${lang}`]}
                onChange={
                  (e: ChangeEvent<HTMLInputElement>) => {
                    setFieldValue(`additionalFields.${key}.${key.includes('no-label') ? 'text' : 'value'}${lang}`, lowerAndTrim(e.target.value))
                    setFormData({ ...formData, additionalFields: { ...formData.additionalFields, [key]: { ...formData.additionalFields[key], [`${key.includes('no-label') ? 'text' : 'value'}${lang}`]: lowerAndTrim(e.target.value) } } })
                  }}
                className={errors[key] ? 'error' : ''}
              />
            </FormGroup>
            <FooterButton variant='delete' onClick={() => onDelete(key)}>x</FooterButton>
          </div>
        )
      })}

      {tempVal.isAdding && <div className="row sb">
        <FormGroup>
          <input type="text"
            value={tempVal[`label${lang}`]}
            onChange={(e) => setTempVal((prev: ITempVal) => ({ ...prev, [`label${lang}` as keyof ITempVal]: lowerAndTrim(e.target.value) }))}
            placeholder={t(`placeholders.${type}.label`, lang)}
            className={tempVal.labelError ? 'error' : ''}
          />
          <input type="text"
            value={tempVal[`value${lang}`]}
            onChange={(e) => setTempVal((prev: ITempVal) => ({ ...prev, [`value${lang}`]: lowerAndTrim(e.target.value) }))}
            placeholder={t(`placeholders.${type}.value`, lang)}
            className={tempVal.valueError ? 'error' : ''}
          />
        </FormGroup>
      </div>}

      {tempVal.isAddingText && <div className="row sb">
        <FormGroup>
          <textarea
            value={tempVal[`text${lang}`]}
            onChange={(e) => setTempVal((prev: ITempVal) => ({ ...prev, [`text${lang}`]: lowerAndTrim(e.target.value) }))}
            rows={3}
            placeholder={t('placeholders.text', lang)}
            className={tempVal.textError ? 'error' : ''}
          />
        </FormGroup>
      </div>}

      <div className="add-info__buttons">
        {type !== 'agriculture_producers' && !tempVal.isAddingText && <>
          {tempVal.isAdding
            ? <>
              <FormBlockButton onClick={onAdd}>{t('save', lang)}</FormBlockButton>
              <FormBlockButton onClick={handleCancelAdd}>{t('cancel', lang)}</FormBlockButton>
            </>
            : <FormBlockButton onClick={() => setTempVal((prev: ITempVal) => ({ ...prev, isAdding: true }))}>
              <AddField /> {t('form.add_field', lang)}
            </FormBlockButton>
          }</>}

        {!tempVal.isAdding && <>
          {tempVal.isAddingText
            ? <>
              <FormBlockButton onClick={onTextAdd}>{t('save', lang)}</FormBlockButton>
              <FormBlockButton onClick={handleCancelAdd}>{t('cancel', lang)}</FormBlockButton>
            </>
            : <FormBlockButton onClick={() => setTempVal((prev: ITempVal) => ({ ...prev, isAddingText: true }))}>
              <AddField /> {t('form.add_info', lang)}
            </FormBlockButton>
          }</>
        }

      </div>

    </FormBlock>
  )
}

export default AdditionalFieldsForm