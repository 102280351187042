import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { downloadReportStats, getFilteredTree } from '../../requests/supervisor.request'
import RegionItem from '../../components/snp-page/region-item.component'
import { ISettlement, ISettlementsObject, ISnp } from '../../interfaces/snp.interface'
import { useTranslation } from 'react-i18next'
import { getRegionList, getStatistics } from '../../requests/kato.request'
import { getLang } from '../../utils/helpers.utils'
import { ReactComponent as DownloadIcon } from '../../assets/icons/download.svg'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  max-height: 85vh;
  overflow-y: auto;
  padding-right: 0.625rem;

  > div:first-child {
    display: flex;
    align-items: center;
    gap: 0.625rem;
    justify-content: space-between;

    svg {
      cursor: pointer;
    }
  }

  ::-webkit-scrollbar {
    width: 2px;
    margin-right: 0.625rem;
  }
  
  ::-webkit-scrollbar-track {
    background-color: transparent; /* You can set a background color here if needed */
    margin-right: 0.625rem;

  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #98A3A9;
    border-radius: 5px; 
    border-top: 15px solid transparent;
    border-bottom: 35px solid transparent;
    background-clip: padding-box;
  }
`

const citiesNamesList = ['Рудный Г.А.', 'Арқалық Қ.Ә.', 'Рудный Қ.Ә.', 'Аркалык Г.А.']

const SnpPage = () => {
  const { t, i18n: { language } } = useTranslation();
  const navigate = useNavigate();

  const [data, setData] = useState<any[]>()
  const [filteredData, setFilteredData] = useState(data);
  const [searchText, setSearchText] = useState('');
  const [searchParams] = useSearchParams();
  const [isImportant, setIsImportant] = useState(false);

  const formatObject = (obj: ISettlementsObject) => {
    getRegionList()
      .then(regionListRes => {
        getStatistics()
          .then(statisticsRes => {
            const regionList = regionListRes.map(item => ({
              kato: item.kato,
              name: item[`name${getLang()}`],
              total: statisticsRes[item.kato].total,
              required: statisticsRes[item.kato].required,
              inProcess: statisticsRes[item.kato].inProcess,
              completed: statisticsRes[item.kato].completed,
              completionPercent: statisticsRes[item.kato].completionPercent * 100,
              children: []
            }))

            const res = Object.keys(obj).map((key) => {
              const statistics = regionList.find(item => item.name === key);
              if (statistics) {
                const { total, inProcess, completed, required, completionPercent, kato } = statistics;
                const region = {
                  name: key,
                  children: Object.keys(obj[key]).map((item) => {
                    const district = {
                      name: item,
                      children: obj[key][item],
                    };
                    return district;
                  }),
                  total,
                  inProcess,
                  required,
                  completed,
                  completionPercent,
                  kato
                };

                return region;
              } else {
                const region = {
                  name: key,
                  children: Object.keys(obj[key]).map((item) => {
                    const district = {
                      name: item,
                      children: obj[key][item],
                    };
                    return district;
                  }),
                };
                return region;
              }

            });

            const last = res.filter((item) => citiesNamesList.includes(item.name));
            const first = res.filter((item) => !citiesNamesList.includes(item.name));
            const sorted = [...first.sort((a, b) => a!.name.localeCompare(b!.name)), ...last];
            setData(sorted);
            setFilteredData(sorted);
          })
      })
  };
  const handleClick = (item: ISnp) => {
    localStorage.setItem('snp', JSON.stringify(item));
    navigate(`/admin/${item.kato}`, { state: { item } });
  }

  const handleDistrictClick = (item: ISettlement) => {
    navigate(`/admin/${item.kato}/district-improvement`, { state: { item } });
  }

  const handleDownloadClick = (kato?: number, name?: string) => {
    downloadReportStats(language.toUpperCase() as 'RU' | 'KZ', kato, name)
  }

  useEffect(() => {
    getFilteredTree(language.toUpperCase() as 'RU' | 'KZ').then((res) => {
      formatObject(res)
    })
  }, [language])


  useEffect(() => {
    setSearchText(searchParams.get('searchText') || '');
  }, [searchParams])

  useEffect(() => {
    if (data) {
      const filteredData = data.map((region) => {
        const matchingDistricts = region.children.map((district: ISettlement) => {
          const matchingSnps = isImportant
            ? district.children.filter((snp: ISnp) =>
              snp[`name${getLang()}`].toLowerCase().includes(searchText.toLowerCase()) && snp.isSupport === true
            )
            : district.children.filter((snp: ISnp) =>
              snp[`name${getLang()}`].toLowerCase().includes(searchText.toLowerCase())
            );
          if (
            (district.name.toLowerCase().includes(searchText.toLowerCase()) && !isImportant) ||
            matchingSnps.length > 0
          ) {
            return {
              ...district,
              children: matchingSnps.length > 0 ? matchingSnps : isImportant ? district.children.filter((snp: ISnp) => snp.isSupport) : district.children,
            };
          }

          return null;
        }).filter(Boolean);

        if (matchingDistricts.length > 0) {
          return {
            ...region,
            children: matchingDistricts,
          };
        }

        return null;
      }).filter(Boolean);
      setFilteredData(filteredData);
    }
  }, [data, searchText, isImportant]);


  return (
    <Wrapper>
      <div>
        <div>
          <input type="checkbox" checked={isImportant} onChange={() => setIsImportant(!isImportant)} /> {t('important-snp')}
        </div>
        <DownloadIcon onClick={() => handleDownloadClick(undefined, 'Карагандинская область')} />
      </div>

      {
        filteredData && filteredData?.length > 0 && filteredData?.map((item) =>
          <RegionItem
            key={item.name}
            region={item}
            onClick={handleClick}
            onDistrictClick={handleDistrictClick}
            onDownloadClick={handleDownloadClick}
          />
        )
      }
    </Wrapper>
  )
}

export default SnpPage