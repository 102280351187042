import { IChartData } from "../interfaces/chart.interface";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Colors,
  BarElement,
  ArcElement,
} from 'chart.js';


import ChartDataLabels from 'chartjs-plugin-datalabels';


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Colors,
  ChartDataLabels,
  BarElement,
  ArcElement,
);

const plugin = {
  id: 'customCanvasBackgroundColor',
  beforeDraw: (chart: any, args: any, options: any) => {
    const { ctx } = chart;
    ctx.save();
    ctx.globalCompositeOperation = 'destination-over';
    ctx.fillStyle = options.color || '#99ffff';
    ctx.fillRect(0, 0, chart.width, chart.height);
    ctx.restore();
  }
};


const lineChartData = (labels: string[], datasets: any[]): IChartData => ({
  labels,
  datasets,
})

const lineChartOptions = (showLegend = false): any => ({
  responsive: true,
  plugins: {
    legend: {
      display: showLegend,
      position: "bottom",
      labels: {
        boxWidth: 6,
        boxHeight: 5,
        usePointStyle: true,
        pointStyle: 'circle',
        font: {
          size: 10,
        },
      }
    },
    title: {
      display: false,
    },
    colors: {
      forceOverride: true
    },
    datalabels: {
      align: 'start',
      anchor: 'start',
      offset: 0,
      display: 'auto',
      color: 'black',
      font: {
        weight: 'bold',
        size: 10,
      },
      clamp: true,
    },
    customCanvasBackgroundColor: {
      color: 'red',
    }
  },
  scales: {
    y: {
      display: false,
    },
    x: {
      grid: {
        display: false,
      },
      ticks: {
        font: {
          size: 10,
        },
      },
    }
  },
  plugis: [plugin],
  maintainAspectRatio: false,
});

const barChartData = (labels: string[], datasets: { label: string, data: number[] }[]): IChartData => ({
  labels,
  datasets,
});

const barChartOptions = (format = false, showLabels = true): any => ({
  responsive: true,
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        font: {
          size: 10,
        },
      },
    },
    y: {
      display: false,
    },
  },
  plugins: {
    title: {
      display: false,
    },
    legend: {
      display: true,
      position: "bottom",
      labels: {
        boxWidth: 6,
        boxHeight: 5,
        usePointStyle: true,
        pointStyle: 'circle',
        font: {
          size: 10,
        },
      },
    },
    datalabels: {
      align: 'start',
      anchor: 'end',
      offset: -5,
      display: showLabels,
      color: 'black',
      padding: {
        bottom: 5,
      },
      font: {
        weight: 'bold',
        size: 7,
      },
      clamp: true,
      formatter: (value: number) => {
        if (format) {
          return `${(value / 1000).toFixed(2)}`
        }
        return value;
      }
    },
  },
});

const sortDataByValueAndLabels = (labels: string[], datasets: any[]) => {
  const data = datasets[0].data;
  const sortedArray = data.sort((a: number, b: number) => a - b);
  const usedIndex: number[] = []

  const resultArray = [
    sortedArray[sortedArray.length - 1],
    sortedArray[1],
    sortedArray[sortedArray.length - 2],
    sortedArray[0],
  ]

  const resultLabels: string[] = []

  resultArray.forEach((value: number) => {
    const neededIndex = data.indexOf(value);
    const label = labels[neededIndex];

    if (!resultLabels.includes(label)) {
      resultLabels.push(label);
      usedIndex.push(data.indexOf(value));
    } else {
      labels.forEach((label, index) => {
        if (!usedIndex.includes(index)) {
          resultLabels.push(label);
          usedIndex.push(index);
        }
      })
    }
  })


  return {
    labels: resultLabels as string[],
    datasets: [{
      data: resultArray
    }]
  }
}

const doughnutChartData = (labels: string[], datasets: any[]): IChartData => ({ labels, datasets });

const doughnutChartOptions: any = {
  cutout: '80%',
  plugins: {
    legend: {
      display: false,
      position: 'right',
      labels: {
        boxWidth: 6,
        boxHeight: 5,
        usePointStyle: true,
        pointStyle: 'circle',
        font: {
          size: 10,
        }
      },
    },
    tooltip: {
      callbacks: {
        label: (context: any) => {
          const value = context.raw || 0;
          return `${value}`;
        },
        title: () => {
          return "";
        }
      },
    },
    elements: {
      arc: {
        borderWidth: 0,
      }
    },
    datalabels: {
      align: 'start',
      anchor: 'start',
      offset: 2,
      display: true,
      color: 'black',
      font: {
        size: 10,
      },
      clamp: true,
    },
  },
  responsive: true,
  maintainAspectRatio: true,
};

const horizontalBarChartData = (labels: string[], data: number[]): IChartData => {
  return {
    labels: [''],
    datasets: [
      ...labels.map((label, index) => ({
        label,
        data: [data[index]],
      })),
    ]
  }

}

const horizontalBarChartOptions: any = {
  responsive: false,
  indexAxis: 'y',
  scales: {
    x: {
      beginAtZero: true,
      display: false,
    },
    y: {
      display: false,
    },
  },
  plugins: {
    legend: {
      display: true,
      position: "bottom",
      labels: {
        boxWidth: 6,
        boxHeight: 5,
        usePointStyle: true,
        pointStyle: 'circle',
        font: {
          size: 10,
        }
      },
    },
    datalabels: {
      align: 'start',
      anchor: 'end',
      display: true,
      clump: true,
      font: {
        size: 10,
      }
    }
  },
  maintainAspectRatio: false,

}

export {
  lineChartData,
  lineChartOptions,
  barChartData,
  barChartOptions,
  doughnutChartData,
  doughnutChartOptions,
  horizontalBarChartData,
  horizontalBarChartOptions,
}