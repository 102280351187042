import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';

import XIcon from '../../assets/icons/x.svg'

import { HeaderContainer, Item, TabButton } from './index.styles';
import { headerOptions, navLinks, tabLinks } from '../../constants/navlinks.constant';
import { ToastContainer, toast } from 'react-toastify';

interface IProps {
  selectedTab: string | null;
  setSelectedTab: (tab: string) => void;
  onClose: () => void;
}

const Header: React.FC<IProps> = ({ selectedTab, setSelectedTab, onClose }) => {
  const { t } = useTranslation();
  const { kato, link, tab } = useParams();
  const navigate = useNavigate();

  const handleTabClick = (option: string) => {

    if (!kato || (kato && navLinks.map(item => item.name).includes(kato as any)) || isNaN(+kato)) {
      toast.error(t('choose-village'));
      return
    }

    setSelectedTab(option)

    let url = option === 'snp' ? `/infrastructure/education` : `/${option}`;

    if (kato && !isNaN(+kato)) {
      url = option === 'snp' ? `/${kato}/infrastructure/education` : `/${kato}/${option}`;
    }
    navigate(url)
  }

  const isSelected = (option: any) => {
    if (kato && link && tab) {
      if (tabLinks.includes(tab)) {
        return option === 'snp'
      } else {
        return option === selectedTab
      }
    } else if (kato && link) {
      if (tabLinks.includes(link)) {
        return option === 'snp'
      } else {
        return option === selectedTab
      }
    } else if (kato) {
      return option === selectedTab
    }

    return false
  }

  useEffect(() => {
    if (kato && link && link !== 'infrastructure' && !isNaN(+kato) && navLinks.map(item => item.name).includes(link as any)) {
      setSelectedTab('education')
    } else if (kato && navLinks.map(item => item.name).includes(kato as any)) {
      setSelectedTab('education')
    }

    // FINISH THAT
  }, [kato, link])

  return (
    <HeaderContainer>
      <Item full>
        {headerOptions.map((option) => (
          <TabButton
            key={option}
            selected={isSelected(option)}
            onClick={() => handleTabClick(option)}>
            {t(option)}
          </TabButton>
        ))}
      </Item>

      <Item onClick={onClose}>
        <img src={XIcon} alt="close icon" />
      </Item>

      <ToastContainer /> 
    </HeaderContainer >
  )
}

export default Header