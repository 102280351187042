import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';

import { FloatingTabsContainer, TabButton } from './index.styles';
import { headerOptions, navLinks } from '../../constants/navlinks.constant';
import { useParams, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

interface IProps {
  selectedTab: string | null;
  setSelectedTab: (tab: string) => void;
}

const FloatingTabs: React.FC<IProps> = ({ selectedTab, setSelectedTab }) => {
  const { t } = useTranslation();
  const { link, kato, tab } = useParams()
  const navigate = useNavigate()

  const handleTabClick = (option: string) => {

    if (!kato || (kato && navLinks.map(item => item.name).includes(kato as any)) || isNaN(+kato)) {
      toast.error(t('choose-village'));
      return
    }

    setSelectedTab(option)

    let url = option === 'snp' ? `/infrastructure/education` : `/${option}`;

    if (kato && !isNaN(+kato)) {
      url = option === 'snp' ? `/${kato}/infrastructure/education` : `/${kato}/${option}`;
    }
    navigate(url)
  }


  return (
    <FloatingTabsContainer>
      {headerOptions.map((option) => (
        <TabButton
          key={option}
          selected={selectedTab === option}
          onClick={() => handleTabClick(option)}>
          {t(option)}
        </TabButton>
      ))}

      <ToastContainer />

    </FloatingTabsContainer>
  )
}

export default FloatingTabs