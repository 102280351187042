import React, { ChangeEvent, useEffect, useState } from 'react'
import { Field } from 'formik'
import { AddField } from '../../assets/icons';
import { FooterButton, FormGroup } from '../admin-page/admin-page.styles';
import { FormBlock, FormBlockButton } from '../insfrastructureProjects/infrastructure.styles'
import { updateObject } from '../../requests/supervisor.request';
import { t } from '../../utils/helpers.utils';
import { sportSectionsList } from '../../constants/snp.constant';
import { toast } from 'react-toastify';

interface IProps {
  formData: any;
  setFormData: (formData: any) => void;
  setFieldValue: (field: string, value: any) => void;
  lang: 'Ru' | 'Kz';
  type: string;
  onSave: (data: any) => void;
  isAddingSportSection: boolean;
  setIsAddingSportSection: (value: boolean) => void;
  newSportSection: { label: string, value: string };
  setNewSportSection: (value: { label: string, value: string }) => void;
}

const SportSections: React.FC<IProps> = (props) => {
  const {
    newSportSection,
    setNewSportSection,
    formData,
    setFormData,
    setFieldValue,
    lang,
    type,
    onSave,
    isAddingSportSection,
    setIsAddingSportSection
  } = props;

  const [list, setList] = useState<any[]>(sportSectionsList);
  const [errors, setErrors] = useState<any>({});

  const handleAdd = () => {
    setErrors({})

    if (!newSportSection.label) {
      setErrors({ label: 'Required' })
    }

    if (!newSportSection.value) {
      newSportSection.label !== 'no' && setErrors({ value: 'Required' })
    }

    if (!newSportSection.label || (newSportSection.label !== 'no' && !newSportSection.value)) {
      toast.error(t('errors.required', lang))
      return;
    }

    const data = { ...formData, sportSections: { ...formData.sportSections, [newSportSection.label]: newSportSection.value } }

    setFormData(data)
    onSave(data.sportSections)

    setNewSportSection({ label: '', value: '' })
    setIsAddingSportSection(false)
  }

  const handleDelete = (key: string) => {
    const { [key]: deleted, ...rest } = formData.sportSections;
    setFormData({ ...formData, sportSections: rest })
    updateObject(type, { ...formData, sportSections: rest })
  }

  const handleCancelAdd = () => {
    setNewSportSection({ label: '', value: '' })
    setIsAddingSportSection(false)
  }

  useEffect(() => {
    const filteredList = sportSectionsList.filter((item) => !formData.sportSections?.[item]);
    setList(filteredList)
  }, [formData])

  return (
    <FormBlock>
      <div className="title">{t(`form.sportSections`, lang)}</div>

      {formData.sportSections && Object.keys(formData.sportSections).length > 0 && Object.keys(formData.sportSections).map((key) => {
        return (
          <div className="row sb" key={key}>
            <FormGroup>
              <label htmlFor={`sportSections.${key}`}>{t(`sport.${key}`, lang)}</label>
              {key !== 'no' && <Field
                id={`sportSections.${key}`}
                name={`sportSections.${key}`}
                value={formData.sportSections[key]}
                onChange={
                  (e: ChangeEvent<HTMLInputElement>) => {
                    setFieldValue(`sportSections.${key}`, e.target.value)
                    setFormData({ ...formData, sportSections: { ...formData.sportSections, [key]: e.target.value } })
                  }}
                as='input'
                type="number"
              />}
              <FooterButton variant='delete' onClick={() => handleDelete(key)}>x</FooterButton>
            </FormGroup>
          </div>
        )
      })}

      {isAddingSportSection && <div className="row sb">
        <FormGroup>
          <select
            value={newSportSection.label}
            onChange={(e) => setNewSportSection({ ...newSportSection, label: e.target.value })}
            placeholder={t('add_sport_type', lang)}
            defaultValue={''}
          >
            <option value="" hidden></option>
            {list.map((item: any) => <option value={item}>
              {t(`sport.${item}`, lang)}
            </option>)}
          </select>


          {newSportSection.label !== 'no' && <input
            type="number"
            value={newSportSection.value}
            onChange={(e) => setNewSportSection({ ...newSportSection, value: e.target.value })}
            placeholder={t('add_sport_number', lang)}
            min={0}
            className={errors['value'] ? 'error' : ''}
          />}
        </FormGroup>
      </div>}

      <div className="add-info__buttons">
        {isAddingSportSection
          ? <>
            <FormBlockButton onClick={handleAdd}>{t('save', lang)}</FormBlockButton>
            <FormBlockButton onClick={handleCancelAdd}>{t('cancel', lang)}</FormBlockButton>
          </>
          : <FormBlockButton onClick={() => setIsAddingSportSection(true)}>
            <AddField /> {t('form.add_field', lang)}
          </FormBlockButton>
        }
      </div>
    </FormBlock>
  )
}

export default SportSections