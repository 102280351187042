import React from 'react'

import { SearchIcon } from '../../assets/icons';

import { SearchInput, SearchWrapper } from './admin-page.styles'
interface IProps {
  searchValue: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const Search: React.FC<IProps> = ({ searchValue, onChange }) => {
  return (
    <SearchWrapper>
      <SearchInput type="text" placeholder="Поиск" value={searchValue} onChange={onChange} />
      <SearchIcon />
    </SearchWrapper>
  )
}

export default Search