import React from 'react';
import { IProject } from '../../interfaces/snp.interface';
import { getLang, t } from '../../utils/helpers.utils';
import { useTranslation } from 'react-i18next';

interface IProps {
  project: IProject
}

const AdditionalFields: React.FC<IProps> = ({ project }) => {
  const { i18n: { language } } = useTranslation();

  return (
    <>
      {project.additionalFields && Object.keys(project.additionalFields).length > 0 && <div className="block">
        <div className="subheading" >{t('form.additionalFields', language)}</div>
        <div className='items'>
          {Object.keys(project.additionalFields).filter((key: string) => !['ru', 'kz'].includes(key)).map((section) =>
            <div className='item' key={section}>
              <div>
                {section.includes('no-label') ? project.additionalFields[section][`text${getLang()}`] : <>{`${project.additionalFields[section][`label${getLang()}`]?.trim()}: `} <b>{project.additionalFields[section][`value${getLang()}`]}</b></>}
              </div>
            </div>
          )}
        </div>
      </div>
      }
    </>
  )
}

export default AdditionalFields