import axios from "axios";
import { IMarker, IRegion, ISettlementsObject } from "../interfaces/snp.interface";

export const getRegionList = (): Promise<IRegion[]> => {
  return axios.get('/api/public/kato/list').then(res => res.data.list);
}

export const getMarkers = (katoCode: number): Promise<IMarker[]> => {
  return axios.get(`/api/public/kato/coordinate?kato=${katoCode}`).then(res => res.data.list);
}

export const getSettlementTree = (language: 'RU' | 'KZ' = 'RU'): Promise<ISettlementsObject> => {
  return axios.get(`/api/public/kato/tree?language=${language}`).then(res => res.data);
}

export const getStatistics = (): Promise<any> => {
  return axios.get('/api/public/kato/statistics').then(res => res.data.data);
}

export const getChildren = (kato: number): Promise<any> => {
  return axios.get(`/api/public/kato/child?kato=${kato}`).then(res => res.data.list)
}