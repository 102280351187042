import React from 'react'
import { getLang } from '../../utils/helpers.utils';
interface IProps {
  renderFields: (language: "Ru" | "Kz" | undefined, setFieldValue: any) => JSX.Element;
  setFieldValue: any
}

const FieldsComponent: React.FC<IProps> = ({ renderFields, setFieldValue }) => {
  return (
    <>
      <div className="grid-item">
        {renderFields(getLang(), setFieldValue)}
      </div>
      <div className="grid-item">
        {renderFields(getLang() !== 'Kz' ? 'Kz' : 'Ru', setFieldValue)}
      </div>
    </>
  )
}

export default FieldsComponent