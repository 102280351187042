import React from 'react'
import { useTranslation } from 'react-i18next';
import { FormBlock, FormGroup } from '../../components/admin-page/admin-page.styles';

interface IProps {
  formData: any[];
  onFormDataChange: (newData: any[]) => void;
  fields: string[];
  onDelete: (id: number) => void;
}

const SingleYearForm: React.FC<IProps> = ({ formData, onFormDataChange, fields }) => {
  const { t } = useTranslation();

  const handleInputChange = (value: string, key: string) => {
    const updatedData = [{ ...formData[0], [key]: +value }];
    onFormDataChange(updatedData);
  }

  return (
    <FormBlock type='white'>
      <div className="labels">
        {
          fields.map((field) => (
            <label htmlFor={field} style={{ width: `${100 / fields.length}%` }}>{t(`form.analytics.${field}`)}</label>
          ))
        }
      </div>
      <FormGroup>
        <div className="analytics">
          {
            fields.map((field) =>
              <input
                type="number"
                value={formData?.[0]?.[field] ? formData?.[0]?.[field] : 0}
                onChange={(e) => handleInputChange(e.target.value, field)}
                name={field}
                style={{ width: `${100 / fields.length}%` }}
                defaultValue={""}
                step={0.001}
                onWheel={(e: any) => e.target.blur()}
              />
            )
          }
        </div>
      </FormGroup>
    </FormBlock >
  )
}

export default SingleYearForm