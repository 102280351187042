import React, { useEffect, useState } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import Tabs from '../../components/admin-page/tabs.component';
import GeneralInfo from '../../components/admin-page/general-info.component';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.9375rem;
`;

const Content = styled.div`
`

const SelectedSnpPage = () => {
  const navigate = useNavigate();
  const { kato } = useParams();

  const [selectedTab, setSelectedTab] = useState<any>(null);
  const [content, setContent] = useState(<GeneralInfo />);

  const handleSelectTab = (tab: string) => {
    setSelectedTab(tab);
    switch (tab) {
      case 'general_info':
        setContent(<GeneralInfo />);
        navigate(`/admin/${kato}`);
        break;
      case 'infrastructure':
        navigate(`/admin/${kato}/infrastructure`);
        break;
      case 'agricultural_producers':
        navigate(`/admin/${kato}/snp_agriculture_producers`);
        break;
      case 'village_improvement':
        navigate(`/admin/${kato}/snp_improvement`);
        break;
      case 'investments':
        navigate(`/admin/${kato}/snp_investment`);
        break;
      case 'district_improvement':
        navigate(`/admin/${kato}/district-improvement`);
        break;
      default:
        navigate(`/admin/${kato}`);
        break;
    }
  }

  useEffect(() => {
    const { pathname } = window.location;

    let selected = null;

    pathname.split('/').forEach((item) => {
      if (['infrastructure', 'snp_agriculture_producers', 'snp_improvement', 'snp_investment', 'district-improvement'].includes(item)) {
        switch (item) {
          case 'snp_agriculture_producers':
            setSelectedTab('agricultural_producers');
            selected = 'agricultural_producers';
            break;
          case 'snp_improvement':
            setSelectedTab('village_improvement');
            selected = 'village_improvement';
            break;
          case 'snp_investment':
            setSelectedTab('investments');
            selected = 'investments';
            break;
          case 'district-improvement':
            setSelectedTab('district_improvement');
            selected = 'district_improvement';
            break;
          default:
            setSelectedTab('infrastructure');
            selected = 'infrastructure';
            break;
        }
      }
    })

    if (!selected) {
      setContent(<GeneralInfo />);
      setSelectedTab('general_info')
    }

  }, [window.location.pathname])

  return (
    <Wrapper>
      <Tabs selectedTab={selectedTab} onSelect={handleSelectTab} />
      {!['general_info'].includes(selectedTab) ? <Outlet /> : <Content>{content}</Content>}
    </Wrapper>
  )
}

export default SelectedSnpPage