import React from 'react'

import { ArrowDown, SelectIcon, Pen } from '../../assets/icons'

import { DistrictWrapper, SnpName } from './index.styles';

import { ISettlement, ISnp } from '../../interfaces/snp.interface';
import { getLang } from '../../utils/helpers.utils';

interface IProps {
  district: ISettlement;
  onClick: (item: ISnp) => void;
  onDistrictClick: (item: any) => void;
}

const DistrictItem: React.FC<IProps> = ({ district, onClick, onDistrictClick }) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const toggleOpen = () => {
    setIsOpen(!isOpen)
  }

  const handleClick = () => {
    onDistrictClick({ kato: district.children[0].pkato })
  }

  return (
    <DistrictWrapper isOpen={isOpen}>
      <div className="district-name" >
        <ArrowDown className='arrow' onClick={toggleOpen} />
        <span>{district.name}</span>
        <Pen className='pen' height={10} onClick={handleClick} />
      </div>

      {isOpen && district.children &&
        <div className='snps'>
          {
            district.children.map((snp) =>
              <div key={snp.kato} className={`snp-name`} onClick={() => onClick(snp)}>
                <SelectIcon height={10} />
                <SnpName bold={snp.isSupport} status={snp.fillingStatus}>{snp[`name${getLang()}`]}</SnpName>
                <Pen className='pen' height={10} />
              </div>
            )
          }
        </div>
      }
    </DistrictWrapper>
  )
}

export default DistrictItem