import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import { reportTypes, reportTypesRouteConfig } from '../../constants/navlinks.constant';
import { InfrastructureTab, InfrastructureTabs } from './infrastructure.styles';
import { useUserContext } from '../../contexts/user.context';

const Infrastructure = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { user } = useUserContext();
  const [selectedReport, setSelectedReport] = useState<any>(null)
  const [reports, setReports] = useState<string[]>(reportTypes);

  useEffect(() => {
    navigate(selectedReport)
  }, [selectedReport, navigate, id])

  useEffect(() => {
    const selected = window.location.pathname.split('/').pop();
    selected && reportTypes.includes(selected) && setSelectedReport(selected);
  }, [])

  useEffect(() => {
    if (user) {
      if (user.permissions?.length > 0) {
        setReports(reportTypesRouteConfig.filter((report) => user.permissions.includes(report.path.toUpperCase())).map((report) => report.path))
      }
    }
  }, [user, user?.permissions, user?.permissions?.length])

  return (
    <div>
      <InfrastructureTabs>
        {
          reports.map(report =>
            <InfrastructureTab
              key={report}
              selected={selectedReport === report}
              onClick={() => setSelectedReport(report)}>{t(`report_types.${report}`)}</InfrastructureTab>)
        }
      </InfrastructureTabs>
      <Outlet />
    </div>
  )
}

export default Infrastructure