import React, { createRef, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { diagramTypes } from '../../constants/snp.constant'
import { getAnalytics } from '../../requests/analytics.request'

import LineChart from '../charts/line.chart'
import BarChart from '../charts/bar.chart'
import HorizontalBarChart from '../charts/horizontal-bar.chart'

import { AnalyticsWrapper } from './index.styles'
import { IMarker } from '../../interfaces/snp.interface'
import { DownloadIcon, Expand2 } from '../../assets/icons'

import { Line } from 'react-chartjs-2'
import { lineChartData, lineChartOptions } from '../../constants/chart.constant'
import { roundNumberArray, unique } from '../../utils/helpers.utils'

const Analytics: React.FC<{ selectedMarker: IMarker | null }> = ({ selectedMarker }) => {
  const { t, i18n: { language } } = useTranslation();

  const excludeKeys = ['id', 'kato', 'year'];

  const [diagramData, setDiagramData] = useState<{ [key: string]: { [key: string]: any } }>({})
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [refList, setRefList] = useState<any>(
    {
      population: createRef<any>(),
      nationality: createRef<any>(),
      employment: createRef<any>(),
      education: createRef<any>(),
      healthcare: createRef<any>(),
      studying: createRef<any>(),
      road: createRef<any>(),
      harvest: createRef<any>(),
      lifestock: createRef<any>(),
      finance: createRef<any>(),
    }
  )

  const isDisplay = (data: any) => {
    return data.some((item: any) => Object.keys(item).filter(key => !excludeKeys.includes(key)).some(key => item[key] !== 0 && item[key] !== null));
  }

  const getDataset = (data: any) => data && data[0] && Object.keys(data[0])
    ?.filter(key => !excludeKeys.includes(key))
    ?.map((key: string) => ({
      label: t(`form.analytics.${key}`),
      data: data.map((item: any) => item[key]),
    }))
    ?.filter((item: { label: string, data: number[] }) => item.data.some((value: number) => value !== 0 && value !== null))
    ?.map((item: { label: string, data: number[] }) => ({ ...item, data: roundNumberArray(item.data.map((value: number) => value === 0 ? null : value)) }))

  const handleOpenDiagram = (type: string) => {
    console.log(type)
  }

  const formatData = useCallback((data: any) => {

    const formattedData = diagramTypes.reduce((acc: any, type, index) => {
      acc[type] = unique(data[index].value, ['year', 'kato']);
      return acc;
    }, {});

    // POPULATION

    formattedData['population'] = {
      data: lineChartData(formattedData['population']?.map((item: any) => `${item.year} ${t('year-short')}`), [{ data: formattedData['population'].map((item: any) => item.populationCount !== 0 ? item.populationCount : null), label: t('form.analytics.populationCount') }]),
      options: lineChartOptions([{ data: formattedData['population']?.map((item: any) => item.populationCount !== 0 ? item.populationCount : null), label: t('form.analytics.populationCount') }].length > 0),
      display: formattedData['population']?.map((item: any) => `${item.year} ${t('year-short')}`).length > 0
    }

    // NATIONALITY

    formattedData['nationality'] = formattedData['nationality']?.filter((item: any) => !(item.kazakhs === null && item.russians === null && item.others === null));

    formattedData['nationality'] = {
      datasets: formattedData['nationality']?.length > 0
        && Object.keys(formattedData['nationality'][0])
          ?.filter(item => !excludeKeys.includes(item))
          ?.map((key: string) => ({
            label: t(`form.analytics.${key}`),
            data: formattedData['nationality'].map((item: any) => item[key]),
          }))
          ?.filter((item: { label: string, data: number[] }) => item.data.some((value: number) => value !== 0)),
      labels: formattedData['nationality']?.map((item: any) => `${item.year} ${t('year-short')}`),
      display: formattedData['nationality']?.map((item: any) => `${item.year} ${t('year-short')}`).length > 0
    }

    formattedData['nationality'].datasets = formattedData['nationality']?.datasets && formattedData['nationality']?.datasets.filter((item: { label: string, data: number[] }) => item.data.some((value: number) => value !== 0 && value !== null));

    // EMPLOYMENT

    const employmentTotal = (
      formattedData['employment'] && formattedData['employment'][0] && Object.keys(formattedData['employment'][0])
        ?.filter((item: any) => !['kato', 'id', 'year', 'totalStreetCount'].includes(item))
        ?.map(item => formattedData['employment'][0][item]).reduce((a: number, b: number) => a + b, 0));

    formattedData['employment'] = {
      datasets: formattedData['employment'].length > 0
        && Object.keys(formattedData['employment'][0])
          ?.filter(item => !['kato', 'id', 'year', 'totalStreetCount'].includes(item)).map((key: string) => ({
            label: t(`form.analytics.${key}`),
            data: formattedData['employment'].map((item: any) => item[key]),
          }))
          ?.filter((item: { label: string, data: number[] }) => item.data.some((value: number) => value !== 0 && value !== null)),
      display: formattedData['employment'].length > 0 && Object.keys(formattedData['employment'][0]).length > 0,
      total: employmentTotal || 0
    };

    formattedData['employment']?.datasets && formattedData['employment']?.datasets?.map((item: { label: string, data: number[] }) => ({ ...item, data: item.data.map((value: number) => value === 0 ? null : value) }));


    // EDUCATION AND HEALTHCARE

    ['education', 'healthcare'].forEach((type: string) => {
      formattedData[type] = {
        data: formattedData[type]?.length > 0 && Object.keys(formattedData[type][0])?.filter(item => !excludeKeys.includes(item)).map((key: string) => formattedData[type][0][key]),
        labels: formattedData[type]?.length > 0 && Object.keys(formattedData[type][0])?.filter(item => !excludeKeys.includes(item)).map((key: string) => t(`form.analytics.${key}`)),
        display: formattedData[type]?.length > 0 && Object.keys(formattedData[type][0])?.length > 0
      }
    });

    // STUDYING, HARVEST, LIFESTOCK, FINANCE

    formattedData['finance'] = formattedData['finance']?.filter((item: any) => !(item.mio === null && item.shtpMemorandum === null && item.shtpProjectImpovement === null));
    formattedData['harvest'] = formattedData['harvest']?.filter((item: any) => !(item.grainCropCount === null && item.oilseedCount === null));

    ['studying', 'harvest', 'lifestock', 'finance'].forEach((type: string) => (
      formattedData[type] = {
        labels: Array.from(new Set(formattedData[type]?.map((item: any) => item.year))),
        datasets: getDataset(formattedData[type]),
        display: isDisplay(formattedData[type])
      }))

    //ROAD

    formattedData['road'] = {
      total: formattedData['road'][0]?.totalStreetCount,
      datasets: formattedData['road'].length > 0
        && Object.keys(formattedData['road'][0])
          ?.filter(item => !['kato', 'id', 'year', 'totalStreetCount'].includes(item)).map((key: string) => ({
            label: t(`form.analytics.${key}`),
            data: formattedData['road'].map((item: any) => item[key]),
          }))
          ?.filter((item: { label: string, data: number[] }) => item.data.some((value: number) => value !== 0 && value !== null))
          ?.map((item: { label: string, data: number[] }) => ({ ...item, data: item.data.map((value: number) => value === 0 ? null : value) })),
      display: formattedData['road'].length > 0 && Object.keys(formattedData['road'][0])
    }
    setDiagramData(formattedData);
  }, [t])

  const getTotal = (val: number, type = 'road') => {
    const str = val?.toString();
    const last = str?.charAt(str.length - 1)

    if (language === 'kz') {
      return type === 'road' ? `Барлығы ${val} көше` : `Барлығы ${val} адам`
    } else {
      if (type === 'employment') {
        return `Всего ${val} человек`
      }

      if (+last === 1) {
        return `Всего ${val} улица`
      } else if (+last > 1 && +last < 5) {
        return `Всего ${val} улицы`
      } else {
        return `Всего ${val} улиц`
      }
    }
  }

  const loadDiagrams = useCallback((kato: number) => {
    if (!kato) {
      toast.error('Выберите населенный пункт')
    }

    setIsLoading(true)

    Promise.allSettled(diagramTypes.map(type => getAnalytics(type, kato)))
      .then((res: any) => {
        formatData(res);
      })
      .finally(() => setIsLoading(false))
  }, [formatData])

  useEffect(() => {
    const kato = selectedMarker?.code;
    kato && loadDiagrams(kato);
  }, [loadDiagrams, selectedMarker]);

  useEffect(() => {
    if (selectedMarker) {
      loadDiagrams(selectedMarker.code);
    }
  }, [language, loadDiagrams, selectedMarker])

  const handleDownload = (ref: any) => {
    if (ref) {
      const canvas = ref.current;
      if (canvas) {
        const image = canvas.toBase64Image();
        const link = document.createElement('a');
        link.href = image;
        link.setAttribute('download', 'chart.png');
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    }
  }

  if (isLoading) return <h2>Загрузка...</h2>

  return <>
    {
      diagramData && Object.keys(diagramData).length > 0
        ? <>
          <AnalyticsWrapper>
            {
              diagramData['population'].display && <div className="container">
                <div className="title">{t(`chartNames.population`)} </div>
                <div className="chart-container" >
                  <Line
                    data={diagramData['population'].data}
                    options={diagramData['population'].options}
                    ref={refList['population']}
                  />
                </div>
              </div>
            }

            {/* {
              diagramData['nationality'].display && <div className="container">
                <div className="title">{t(`chartNames.nationality`)}</div>
                <div className="chart-container" >
                  <BarChart
                    labels={diagramData['nationality'].labels}
                    datasets={diagramData['nationality'].datasets} />
                </div >
              </div>
            } */}

            {
              diagramData['employment'].display && <div className="container">
                <div className="title">{t(`chartNames.employment`)} ({getTotal(diagramData['employment'].total, 'employment')})</div>
                <div className="chart-container" >
                  <BarChart
                    datasets={diagramData['employment'].datasets}
                    labels={['']}
                  />
                </div >
              </div>
            }

            {
              (diagramData['education'].display || diagramData['healthcare'].display)
              && <div className="container white">
                {
                  diagramData['education'].display && <div className="container half">
                    <div className="title">{t(`chartNames.education`)}</div>
                    <div className="chart-container">
                      <HorizontalBarChart
                        labels={diagramData['education'].labels}
                        data={diagramData['education'].data} />
                    </div>
                  </div>
                }

                {
                  diagramData['healthcare'].display && <div className="container half">
                    <div className="title">{t(`chartNames.healthcare`)}</div>
                    < div className="chart-container" >
                      <HorizontalBarChart
                        labels={diagramData['healthcare'].labels}
                        data={diagramData['healthcare'].data} />
                    </div>
                  </div>
                }
              </div>
            }

            {
              diagramData['studying'].display && <div className="container">
                <div className="title">{t(`chartNames.studying`)}</div>
                <div className="chart-container">
                  <BarChart
                    labels={diagramData['studying'].labels}
                    datasets={diagramData['studying'].datasets} />
                </div>
              </div>
            }

            {
              diagramData['road'].display && <div className="container">
                <div className="title">{t(`chartNames.road`)} ({getTotal(diagramData['road'].total)})</div>
                <div className="chart-container">
                  <BarChart
                    labels={['']}
                    datasets={diagramData['road'].datasets} />
                </div>
              </div>
            }

            {
              diagramData['harvest'].display && <div className="container">
                <div className="title">{t(`chartNames.harvest`)}</div>
                <div className="chart-container">
                  <LineChart
                    datasets={diagramData['harvest'].datasets}
                    labels={diagramData['harvest'].labels} />
                </div>
              </div>
            }

            {
              diagramData['lifestock'].display && <div className="container">
                <div className="title">{t(`chartNames.lifestock`)}</div>
                <div className="chart-container">
                  <BarChart
                    labels={diagramData['lifestock'].labels}
                    datasets={diagramData['lifestock'].datasets}
                    format={true}
                    showLabels={true}
                  />
                </div>
              </div>
            }

            {
              diagramData['finance'].display && <div className="container">
                <div className="title">{t(`chartNames.finance`)}</div>
                <div className="chart-container" style={{ maxHeight: '10rem' }}>
                  <BarChart
                    labels={diagramData['finance'].labels}
                    datasets={diagramData['finance'].datasets}
                  />
                </div>
              </div>
            }
          </AnalyticsWrapper>
        </>
        : <>
          <h2>{t('no-data')}</h2>
        </>
    }
  </>
}

export default Analytics