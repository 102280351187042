import React, { useContext, useEffect, useState } from 'react';
import { Routes, Route, useSearchParams } from 'react-router-dom';
import BasicLayout from '../layout/basic.layout';
import Home from './home/home.page.';
import AdminPage from './admin/admin.page';
import Snp from './admin/snp-admin.page';
import Analytics from './admin/analytics-admin.page';
import Sections from './admin/sections-admin.page';
import SelectedSnp from './admin/selected-snp-admin.page';
import Infrastructure from '../components/insfrastructureProjects/infrastructure.component';
import Reports from '../components/insfrastructureProjects/reports.component';
import i18n from '../i18n';
import { IRouteConfig, formsRouteConfig, reportTypesRouteConfig } from '../constants/navlinks.constant';
import { useUserContext } from '../contexts/user.context';
import AgricultureProducersForm from '../components/admin-page/agriculture-producers-form.component';
import VillageImprovementForm from '../components/admin-page/village-improvement-form.component';
import InvestmentForm from '../components/admin-page/investment-form.component';
import DistrictImprovementForm from '../components/admin-page/district-improvement-form.component';
import EducationForm from '../components/insfrastructureProjects/education-form.component';
import HealthForm from '../components/insfrastructureProjects/health-form.component';
import CultureSportForm from '../components/insfrastructureProjects/culture-sport-form.component';
import WaterSupplyForm from '../components/insfrastructureProjects/water-supply-form.component';
import GasSupplyForm from '../components/insfrastructureProjects/gas-supply-form.component';
import InternetForm from '../components/insfrastructureProjects/internet-form.component';
import AccessRoadForm from '../components/insfrastructureProjects/access-road-form.component';
import VillageRoadForm from '../components/insfrastructureProjects/village-road-form.component';
import LightingForm from '../components/insfrastructureProjects/lighting-form.component';

const RoutesIndex = () => {
  const [params] = useSearchParams();
  const { user } = useUserContext()

  const [reports, setReports] = useState<IRouteConfig[]>(reportTypesRouteConfig);
  const [forms, setForms] = useState<IRouteConfig[]>(formsRouteConfig);

  useEffect(() => {
    if (params.get('lang')) {
      i18n.changeLanguage(params.get('lang') as 'kz' | 'ru')
    }
  }, [params])

  useEffect(() => {
    if (user) {
      if (user.permissions?.length > 0) {
        setReports(reportTypesRouteConfig.filter((report) => user.permissions.includes(report.path.toUpperCase())))
        setForms(formsRouteConfig.filter((form) => user.permissions.includes(form.path.toUpperCase().replace('-', '_'))))
      }
    }
  }, [user, user?.permissions, user?.permissions?.length])

  return (
    <Routes>
      <Route path='/' element={<BasicLayout />}>
        <Route index element={<Home />} />
        <Route path='/:kato' element={<Home />} />
        <Route path='/:kato/:link' element={<Home />} />
        <Route path='/:kato/:link/:tab' element={<Home />} />
      </Route>
      <Route path='/admin' element={<AdminPage />} >
        <Route path='snp' element={<Snp />} />
        <Route path='analytics' element={<Analytics />} />
        <Route path='sections' element={<Sections />} />
        <Route path=':kato' element={<SelectedSnp />}>
          <Route path='infrastructure' element={<Infrastructure />}>
            <Route path=':type' element={<Reports />} />
          </Route>
          <Route path='infrastructure/education/:objectId' element={<EducationForm />} />
          <Route path='infrastructure/health/:objectId' element={<HealthForm />} />
          <Route path='infrastructure/culture-sport/:objectId' element={<CultureSportForm />} />
          <Route path='infrastructure/water-supply/:objectId' element={<WaterSupplyForm />} />
          <Route path='infrastructure/gas-supply/:objectId' element={<GasSupplyForm />} />
          <Route path='infrastructure/internet-communication/:objectId' element={<InternetForm />} />
          <Route path='infrastructure/access-road/:objectId' element={<AccessRoadForm />} />
          <Route path='infrastructure/village-road/:objectId' element={<VillageRoadForm />} />
          <Route path='infrastructure/lighting/:objectId' element={<LightingForm />} />
          <Route path=':type' element={<Reports />} />
          <Route path='snp_agriculture_producers/:objectId' element={<AgricultureProducersForm />} />
          <Route path='snp_improvement/:objectId' element={<VillageImprovementForm />} />
          <Route path='snp_investment/:objectId' element={<InvestmentForm />} />
          <Route path='district-improvement/:objectId' element={<DistrictImprovementForm />} />
        </Route>
      </Route>
      <Route path='*' element={<div>
        <h2>Страницы не существует или у вас отсутствует доступ</h2>
        <a href='/'>На главную</a>
      </div>} />
    </Routes>
  )
}

export default RoutesIndex