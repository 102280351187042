import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';

import { FormBlock, FormGroup } from '../../components/admin-page/admin-page.styles';
import { analyticsFields } from '../../constants/analytics.constant';

interface IProps {
  formData: any[];
  onFormDataChange: (newData: any[]) => void;
  fields: string[];
}

const defaultYears = Array.from({ length: 7 }, (_, i) => i + 2018);

const YearsForm: React.FC<IProps> = ({ formData, onFormDataChange, fields }) => {
  const { t } = useTranslation();
  const [data, setData] = useState<any[]>(formData);

  const handleInputChange = (year: string, value: string, key: string) => {
    if (analyticsFields.finance.includes(key) && value.length > 4) {
      return;
    }

    if (data.find((item) => item.year === +year)) {
      onFormDataChange(data.map((item) => item.year === +year ? { ...item, [key]: +value } : item));
    } else {
      onFormDataChange([...data, { year: +year, [key]: +value }]);
    }
  }

  useEffect(() => {
    const formatted = defaultYears.map((year) => ({
      ...fields.reduce((acc: any, field) => ({ ...acc, [field]: null }), {}),
      ...formData.find(item => item.year === year),
    }))

    setData(formatted);
  }, [formData, fields])

  return (
    <FormBlock type='white'>
      <div className="labels">
        <label htmlFor={'year'} style={{ width: `${100 / fields.length}%` }}>{t(`form.analytics.year`)}</label>
        {

          fields.map((field) => (
            <label htmlFor={field} style={{ width: `${100 / fields.length}%` }}>{t(`form.analytics.${field}`)}</label>
          ))
        }
      </div>

      {defaultYears.map((year) => (
        <FormGroup key={year}>
          <div className="analytics">
            <label htmlFor={year.toString()} style={{ width: `${100 / fields.length + 1}%` }} >{year} г.</label>
            {
              fields.map((field) =>
                <input
                  type="number"
                  value={data.find(item => item.year === year) && data.find(item => item.year === year)?.[field] ? data.find(item => item.year === year)?.[field] : ''}
                  onChange={(e) => {
                    handleInputChange(year.toString(), e.target.value, field)
                  }}
                  name={field}
                  style={{ width: `${100 / fields.length}%` }}
                  step={0.001}
                  onWheel={(e: any) => e.target.blur()}
                />
              )
            }
          </div>
        </FormGroup>
      ))}
    </FormBlock>
  )
}

export default YearsForm