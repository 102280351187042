import React, { useEffect, useState } from 'react'
import FloatingTabs from './floatin-tabs.component';
import Header from './header.component';
import Content from './content.component';

import { RightMenuContainer } from './index.styles';
import { IMarker } from '../../interfaces/snp.interface';
import { navLinks } from '../../constants/navlinks.constant';
import { useParams, useNavigate } from 'react-router-dom';

interface IProps {
  selectedLink: string | null;
  setSelectedLink: (link: string | null) => void;
  selectedMarker: IMarker | null;
}

const RightMenu: React.FC<IProps> = ({ selectedLink, setSelectedLink, selectedMarker }) => {
  const [selectedTab, setSelectedTab] = useState<string | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const { link, kato, tab } = useParams()
  const navigate = useNavigate();

  const closeModal = () => {
    setIsOpen(false);
  }

  useEffect(() => {
    if (tab && link && link === 'infrastructure') {
      setSelectedTab(tab)
      setIsOpen(true)
    } else if (navLinks.map(item => item.name).includes(kato as any) && link) {
      setSelectedTab(link)
      setIsOpen(true)
    } else if ((kato && navLinks.map(item => item.name).includes(kato as any)) || (link && navLinks.map(item => item.name).includes(link as any))) {
      setIsOpen(true)
    } else if (kato && ['analytics', 'akim', 'info'].includes(kato as string)) {
      setSelectedTab(kato)
      setIsOpen(true)
    } else if (link && ['analytics', 'akim', 'info'].includes(link as string)) {
      setSelectedTab(link)
      setIsOpen(true)
    }

  }, [link, kato, tab])

  useEffect(() => {
    if (selectedTab) {
      setIsOpen(true)
    }
  }, [selectedTab])

  return (
    <>
      {isOpen
        ? <RightMenuContainer>
          <Header selectedTab={selectedTab} setSelectedTab={setSelectedTab} onClose={closeModal} />
          <Content selectedTab={selectedTab} selectedLink={selectedLink} selectedMarker={selectedMarker} setSelectedTab={setSelectedTab} />
        </RightMenuContainer>
        : <FloatingTabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
      }
    </>
  )
}

export default RightMenu