import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { reportTypes, tabs } from '../../constants/navlinks.constant'

import { TabsContainer, Tab } from './admin-page.styles'
import { useUserContext } from '../../contexts/user.context'

const tabToType: any = {
  agricultural_producers: 'SNP_AGRICULTURE_PRODUCERS',
  village_improvement: 'SNP_IMPROVEMENT',
  investments: 'SNP_INVESTMENT',
}

interface IProps {
  selectedTab: string,
  onSelect: (tab: string) => void
}

const Tabs: React.FC<IProps> = ({ selectedTab, onSelect }) => {
  const { user } = useUserContext();
  const [tabList, setTabList] = useState<any>(tabs);

  useEffect(() => {
    const location = window.location.href
    setTabList(location.includes('district') ? ["district_improvement"] : tabs)
  }, [])

  useEffect(() => {
    if (user) {
      if (user.permissions?.length > 0) {
        let filtered: any = []
        filtered = tabs.filter((tab) => user.permissions.includes(tabToType[tab]))
        if (user.permissions.some((item: string) => reportTypes.includes(item.toLowerCase()))) {
          filtered = ['infrastructure', ...filtered]
        }
        setTabList(['general_info', ...filtered])
      }
    }
  }, [user, user?.permissions, user?.permissions?.length])

  const { t } = useTranslation()

  return (
    <TabsContainer>
      {
        tabList.map((tab: string) =>
          <Tab key={tab} isSelected={selectedTab === tab} onClick={() => onSelect(tab)}>
            {t(`navLinks.${tab}`)}
          </Tab>)
      }
    </TabsContainer>
  )
}

export default Tabs