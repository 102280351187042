import styled, { css } from "styled-components";
import { Container, ModalContent } from "../index.style";
import ArrowRight from '../../assets/icons/arrow-right.svg'

const Heading = styled.div`
  width: 100%;
  padding: 0.625rem 1.25rem;
  border: 1px solid #F5F5F5;
  border-radius: 10px;
  text-transform: uppercase;

  span {
    text-transform: capitalize;
    cursor: pointer;

    &.active {
      font-weight: 700;
      color: #118F84;
    }
  }

 
`;

const Info = styled.div`
  display: flex;
  gap: 0.625rem;
  height: 100%;

  .content{
    flex: 1;
  }
`;

const NavBar = styled(Container)`
  flex-direction: column;
  gap: 0.625rem;
  width: 12.5rem;
  height: 100%;
`;

const NavItem = styled.div<{ selected: boolean }>`
  width: 100%;
  border-radius: 3.125rem;
  border: 1px solid #DBDCE0;
  padding: 0.375rem 0.9375rem;
  cursor: pointer;
  background: #fff;

  p {
    max-width: 7rem;
    white-space: break-all;
    font-size: 0.75rem;
    color: #606367;
  }

  ${({ selected }) => selected && css`
    background: linear-gradient(180deg, #2366CA 0%, #02AC51 100%);
    position: relative;
    
    p {
      color: #fff;
      font-weight: 700;
    }
    
    &:after{
      content: "";
      background-image: url(${ArrowRight});
      background-size: 0.25rem 0.5rem;
      position: absolute;
      right: 0.9375rem;
      width: 0.25rem;
      height: 0.5rem;
      top: 50%;
      transform: translateY(-50%);  
    }
  `}
`;

const FloatingTabsContainer = styled.div`
  margin-left: 0.625rem;
  margin-top: 0.625rem;
  padding: 0.625rem;
  border-radius: 10px;
  background: #F5F5F5;
  display: flex;
  gap: 0.625rem;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.30);
  position: relative;
  z-index: 10;
  height: fit-content;
`;

const TabButton = styled.button<{ selected: boolean }>`
  border-radius: 30px;
  border: 1px solid #DBDCE0;
  padding: 0px 0.9375rem;
  cursor: pointer;
  height: 1.6875rem;
  text-transform: uppercase;
  font-size: 0.75rem;

  ${({ selected }) => selected && css`
    background: linear-gradient(180deg, #2366CA 0%, #02AC51 100%);
    color: #FFFFFF;
  `}
`;

const HeaderContainer = styled.div`
  width: 100%;
  height: 2.9375rem;
  display: flex;
  gap: 0.625rem;
`;

const Item = styled(Container) <{ full?: boolean }>`
  gap: 0.625rem;

  ${({ full }) => full && css`
    flex: 1;
  `}

  img {
    cursor: pointer;
  }
`;

const RightMenuContainer = styled.div`
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.30);
  border-radius: 0px 10px 10px 0px;
  background: #FFF;
  flex: 1;
  margin-left: -10px;
  padding: 0.625rem 0.625rem 0.625rem 1.25rem; 
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  z-index: 5;
  position: relative;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  
  ${ModalContent}{
    left: 60%;
  }
`;

const EducationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  max-height: 80vh;
  overflow-y: auto;
  padding-right: 0.625rem;

  ::-webkit-scrollbar {
    width: 2px;
    margin-right: 0.625rem;
  }
  
  ::-webkit-scrollbar-track {
    background-color: transparent; /* You can set a background color here if needed */
    margin-right: 0.625rem;

  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #98A3A9;
    border-radius: 5px; 
    border-top: 15px solid transparent;
    border-bottom: 35px solid transparent;
    background-clip: padding-box;
  }  
`;

const EducationItem = styled.div<{ isType?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  border-radius: 0.625rem;
  border: 1px solid #DBDCE0;
  width: 100%;
  background: linear-gradient(0deg, #F5F5F5 0%, #FFFFFF 100%);

  .body {
    padding: 0 0.9375rem 0.9375rem 0.9375rem;
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
  }

  .heading,
  .subheading {
    color: #118F84;
    font-size: 14px;
    width: 100%;
  }

  .with-icon {
    display: flex;
    gap: 0.625rem;
    align-items: center;
    cursor: pointer;
   
    svg {
      min-width: 0.625rem;
      min-height: 0.625rem;
    }

    path {
      stroke: #118F84;
    }
  }

  .heading {
    font-weight: 700;
    padding: 0.8125rem 0.9375rem;
    border-radius: 10px;
    border-bottom: 3px solid #DBDCE0;
    text-transform: uppercase;
    background: #F1FAF3;
    cursor: pointer;
  }

  .items {
    display: flex;
    width: 100%;
    gap: 0.625rem;
    flex-wrap: wrap;
  }

  .item {
    padding: 0.3125rem 0.625rem;
    border-radius: 0.3125rem;
    background: #F5F5F5;
    display: flex;
    gap: 0.375rem;
   
    > div {
      display: flex;
      gap: 0.3125rem;
      font-size: 0.875rem;
      align-items: center;
    }

    b {
      font-size: 0.875rem;
    }
  }


  .block {
    display: flex;
    flex-direction: column;
    gap: 0.375rem;
    border-radius: 0.625rem;
    border: 1px solid #DBDCE0;
    padding: 0.625rem;
  }

  .subheading{
    padding-bottom: 0.3125rem;
    font-weight: 500;
    width: 100%;
    border-bottom: 1px solid #DBDCE0;
  }

  .text-block {
    display: flex;
    flex-direction: column;
    gap: 0.375rem;
  }

  .text {
    font-size: 14px;
    color: #606367;
  }

  .images {
    display: flex;
    gap: 0.625rem;
    flex-wrap: wrap;
    img{
      max-width: 14.375rem;
      max-height: 8.1875rem;
      border-radius: 0.625rem;
      cursor: pointer;
    }

    .footer{ 
      display: flex;
      justify-content: space-between;
      width: 100%;

      button { 
        all: unset;
        cursor: pointer;
      }
    }
  }

  .images__item{
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
    align-items: flex-end;
  }

  .images__date{
    font-size: 0.75rem;
    font-style: italic;
  }

  .memorandum-year {
    padding-top: 0.625rem;
  }

  .street-list-heading{
    color: #118F84;
    line-height: 1;
  }
`;

const ZoomableImageContainer = styled.div`
  position: relative;
  // max-width: 60vw; 
  // width: 60vw; 
  margin: 0 auto; 
  display: flex;
  justify-content: center;
  align-items: center;

  .react-transform-wrapper {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    .react-transform-component{
      // width: 90%;
      height: auto;
      margin: 0;
    }
  }

  .arrow {
    position: relative;
    cursor: pointer;
  }

  .prev {
    left: -2rem;
  }

  .next {
    right: -2rem;
  }

  .tools {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 0;
    right: -2rem;
    z-index: 100;
    gap: 0.625rem;

    .zoom {
      width: 3rem;
      height: 3rem;
      radius: 0.625rem;
      background: rgba(0, 0, 0, 0.8);
      color: #FFF;
      border: 0;
      border-radius: 0.625rem;
    }
  }

  button{
    background: #fff;
    border: 1px solid #DBDCE0;
    padding: 0.3125rem 0.625rem;
    cursor: pointer;

    svg{
      width: 0.75rem;
      height: 0.75rem;
    }
  }

  img {
    height: auto; 
    margin: 0 auto;
    max-height: 70vh;
  }

  .modal-body {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 1.25rem;
  }

  .footer {
    display: flex;
    justify-content: space-between;
    background: black;
    width: 100%;
    border-bottom-right-radius: 1.25rem;
    border-bottom-left-radius: 1.25rem;
    height: 2.5rem;
    align-items: center;
    padding: 0 1.25rem;

    .object-info {
      flex: 1;
      text-align: center; 
      display: flex;
      gap: 0.625rem;
      justify-content: center;
      align-items: center;
    }
  }

  .download{
    cursor: pointer;
    display: flex;
    gap: 0.3125rem;
    align-items: flex-end;
  }
 
`;

const AnalyticsWrapper = styled.div`
  display: flex;
  gap: 0.625rem;
  max-width: 100%;
  max-height: 100%;
  flex-wrap: wrap;
  
  .container{
    display: flex;
    flex-direction: column;
    background: #DBDCE0;
    border-radius: 0.625rem;
    height: 12.5rem;
    .title {
      width: 100%;
      border-radius: 0.625rem;
      border: 1px solid #DBDCE0;
      background: #FFF;
      font-size: 0.75rem;
      font-weight: 700;
      padding: 0.4375rem 0.625rem;
      min-height: 1.75rem;
      white-space: wrap;
      max-width: 20rem;
      // flex: 1;
    }

    .chart-container {
      max-height: 10.75rem;
      width: 20rem;
      background: #FFF;
      border-radius: 0.625rem;
      border: 1px solid #DBDCE0;
      padding: 0.625rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      position: relative;

      .doughnut-total {
        position: absolute;
        font-size: 1.5rem;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      .bar-total {
        position: absolute;
        font-size: 0.75rem;
        right: 0.5rem;
        top: 0;
      }
    }
  }

  .half {
    height: fit-content;
    .chart-container {
      height: 4.1875rem;
    }
  }

  .white {
    background: #FFF;
    gap: 0.625rem;
  }

  .labels { 
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
    padding: 0.625rem;
    align-items: flex-start;
    justify-content: flex-start;  

    .label {
      display: flex;
      gap: 0.625rem;
      justify-content: flex-start;
      align-items: center;
    }
    
    .text {
      font-size: 0.625rem;  
      text-align: left;
    }

    .box{
      width: 0.5rem;
      height: 0.5rem;
    }
  }
`;

const AgricultureWrapper = styled.div`
  padding: 0.625rem 0.625rem 0.625rem 1.25rem;
  .list-heading{
    margin-bottom: 0.625rem;
    display: flex;
    align-items: center;
    gap: 0.625rem;
    color: #118F84;
    font-weight: 700;
    font-size: 1.2rem;
    line-height: 1.2rem;
    cursor: pointer;
    path {
      stroke: #118F84;
    }
  }

  .tabs {
    display: flex;
    gap: 0.625rem;
    margin-bottom: 0.625rem;

    .tab {
      cursor: pointer;
    }

    .tab.active {
      color: #118F84;
      font-weight: 700;
    }
  }

  .compare-columns {
    display: flex;
    width: 100%;

    .column {
      flex: 1;
    }
  }
`

const PassportWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;

  
  .item {
    border-radius: 0.625rem;
    background: #FFF;
    border: 1px solid #DBDCE0;
    display: flex;
    gap: 0.625rem;
  };

  .container {
    padding: 0.625rem;
    border-radius: 0.625rem;
    border: 1px solid #DBDCE0;
    background: #FFF;

    &.supervisor{
      background: #F5F5F5;
    }

    &.police .item {
      border: 0;
    }
  }

  .photo {
    width: 6.25rem;
    height: 8.31369rem;
    border-radius: 0.625rem;
    border: 1px solid #DBDCE0;  
    cursor: pointer;
  };

  .info {
    width: 100%;
  }

  .name {
    color: #118F84;
    font-family: Segoe UI;
    font-size: 1rem;
    font-weight: 700;
    text-transform: uppercase;
  }

  .group {
    gap: 0.625rem; 
  }

  .position {
    color: #606367;
    font-variant-numeric: lining-nums tabular-nums;
    font-size: 0.875rem;
    font-weight: 400;
  }

  .row {
    display: flex;
    gap: 0.625rem;
    align-items: center;
  }

  .works-from {
    padding: 0.125rem 0.3125rem;
    border-radius: 0.3125rem;
    background: #F5F5F5;
    color: #606367;
    font-size: 0.875rem;
    font-weight: 300;
  }

  .date { 
    color: #606367;
    font-size: 0.875rem;
    font-weight: 300;
  }

  .divider {
    width: 100%;
    height: 1px;
    background: #DBDCE0;
    margin: 0.94rem 0;
  }

  .phone {
    display: flex;
    gap: 0.625rem;
    img {
      width: 0.875rem;
      height: 0.875rem;
    }
  }

  .number {
    font-size: 0.875rem;
    font-weight: 700;
    color: #2366CA;
    text-decoration: underline;
  }

  .other {
    color: #606367;
    font-size: 0.75rem;
    font-weight: 400;
  }

  .photo-container{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 0.625rem;
  }

  .download-button {
    all: unset;
    cursor: pointer;
  }

  .with-icon {
    display: flex;
    gap: 0.625rem;
    font-size: 0.75rem;
    color: #606367;
    svg {
      width: 1rem;
    }
  }

  .items {
    display: flex;
    width: 100%;
    gap: 0.625rem;
    flex-wrap: wrap;

    &__item {
      padding: 0.3125rem 0.625rem;
      border-radius: 0.3125rem;
      background: #F5F5F5;
      display: flex;
      gap: 0.375rem;
     
      > div {
        display: flex;
        gap: 0.3125rem;
        font-size: 0.875rem;
        align-items: center;
      }
  
      b {
        font-size: 0.875rem;
      }
    }
  }
`;

const SnpInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;

  .container {
    display: flex;
    padding: 0.625rem;
    gap: 0.625rem;
    border-radius: 0.625rem;
    border: 1px solid #DBDCE0;
    align-items: center;
    &.yellow {
      background: linear-gradient(0deg, #FDFBF2 0%, #FDFBF2 100%), #F5F5F5;
    }
  }

  .population-all {
    padding: 0.625rem;
    display: flex;
    gap: 1.25rem;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex-direction: column;

    > div {
      display: flex;
      gap: 0.3125rem;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
  }

  .population-grid {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
  }

  .grid-row{
    display: flex;
    gap: 0.625rem;
    width: 100%;

    .grid-item {
      border-radius: 1.25rem;
      border: 1px solid #E4E5E8;
      flex: 1;
    }

    .header {
      padding: 0.625rem;
      background: #FFF;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      text-align: center;
      border-top-left-radius: 1.25rem;
      border-top-right-radius: 1.25rem;
      gap: 0.625rem;
    }

    .value{
      padding: 0.625rem;
      display: flex;
      flex-direction: column;
      gap: 0.625rem;
      align-items: center;
      justify-content: center;
      border-bottom-left-radius: 1.25rem;
      border-bottom-right-radius: 1.25rem;
      background: #F5F5F5;
      text-align: center;
    }
  }

  .row {
    width: 100%;
    display: flex;
    gap: 1.25rem;
  }
`;

export {
  Heading,
  Info,
  NavBar,
  NavItem,
  FloatingTabsContainer,
  TabButton,
  HeaderContainer,
  Item,
  RightMenuContainer,
  EducationContainer,
  EducationItem,
  ZoomableImageContainer,
  AnalyticsWrapper,
  AgricultureWrapper,
  PassportWrapper,
  SnpInfoWrapper,
}
