import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import Routes from './pages';

import './index.scss';
import './i18n';
import { UserProvider } from './contexts/user.context';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <BrowserRouter>
    <UserProvider>
      <Routes />
    </UserProvider>
  </BrowserRouter>
);


