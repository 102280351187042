import React, { FC, ChangeEvent, useEffect, useState } from 'react'
import { Field } from 'formik'
import { AddField } from '../../assets/icons';
import { FooterButton, FormGroup } from '../admin-page/admin-page.styles'
import { FormBlock, FormBlockButton } from '../insfrastructureProjects/infrastructure.styles'
import { documentationStatusOptions } from '../../constants/snp.constant';
import { lowerAndTrim, t } from '../../utils/helpers.utils';

interface IProps {
  formData: any
  setFormData: (formData: any) => void
  setFieldValue: (field: string, value: any) => void
  lang: 'Ru' | 'Kz'
  errors?: any,
  tempFund: any,
  setTempFund: (data: any) => void,
  onAdd: any,
  onDelete: any
  streetId: number | string,
  setNewStreet?: any
  newStreet?: any
}

const defaultBody = {
  labelRu: '', labelKz: '', value: '', isAdding: false, labelError: false, valueError: false
}

const StreetDocumentationForm: FC<IProps> = (props) => {
  const {
    formData,
    setFormData,
    setFieldValue,
    lang,
    errors,
    tempFund,
    setTempFund,
    onAdd,
    onDelete,
    streetId,
    setNewStreet,
    newStreet
  } = props;

  const [dates, setDates] = useState<any>({
    [streetId]: {
      workStart: null,
      workEnd: null
    }
  });

  const handleCancelAdd = () => {
    setTempFund((prev: any) => ({ ...prev, [streetId]: defaultBody }))
  }

  const handleValueChange = (key: string, val: any, setFieldValue: (fieldName: string, value: string) => void) => {
    if (key === 'workStart' || key === 'workEnd') {
      if (val.split('-')[0].length > 4) return;
    }

    const found = formData.streetList.find((item: any) => item.id === streetId);
    if (found) {
      setDates((prev: any) => ({ ...prev, [key]: val }))
      setFieldValue(key, val);
      setFormData((prev: any) => (
        {
          ...prev,
          streetList: prev.streetList.map((item: any) => item.id === streetId ? { ...item, [key]: val } : item)
        }))
    } else if (streetId === 'new') {
      setDates((prev: any) => ({ ...prev, [key]: val }))
      setFieldValue(key, val);
      setNewStreet((prev: any) => ({ ...prev, [key]: val }))
    }
  }

  const getStreet = () => streetId === 'new' ? newStreet : formData.streetList.find((item: any) => item.id === streetId) || null

  useEffect(() => {
    setDates((prev: any) => (
      { ...prev, [streetId]: { workStart: prev[streetId].workStart || null, workEnd: prev[streetId].workStart || null } }
    ))
  }, [streetId])

  return (
    <>
      <FormBlock>
        <div className="row sb wrap">
          <FormGroup>
            <label className="required" htmlFor={`streets.${streetId}.documentationStatus`}>{t(`form.documentationStatus`, lang)}</label>
            <Field
              id={`documentationStatus`}
              name={`documentationStatus`}
              as={'select'}
              className={errors['documentationStatus'] ? 'error' : ''}
              onChange={(e: ChangeEvent<HTMLSelectElement>) => handleValueChange('documentationStatus', e.target.value, setFieldValue)}
              value={getStreet()?.documentationStatus}
            >
              <option value={""} selected hidden></option>
              {documentationStatusOptions.map((option) => <option value={option.value} key={option.value}>{t(`form.documentationStatusObj.${option.value}`, lang)}</option>)}
            </Field>
          </FormGroup>
        </div>

        {
          getStreet()?.documentationStatus === 'HAVE' && <>
            <div className="row sb wrap">
              <FormGroup>
                <label className="required" htmlFor={`streets.${streetId}.workStart`}>{t(`form.workStart`, lang)}</label>
                <Field
                  id={`workStart`}
                  name={`workStart`}
                  placeholder={t(`form.workStart`, lang)}
                  as="input"
                  type="date"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => handleValueChange('workStart', e.target.value, setFieldValue)}
                  max={dates.workEnd || getStreet()?.workEnd?.split('T')[0] || null}
                  className={errors['date'] ? 'error' : ''}
                  defaultValue={getStreet()?.workStart?.split('T')[0] || null}
                  value={dates.workStart || getStreet()?.workStart?.split('T')[0] || null}
                />
              </FormGroup>
            </div>
            <div className="row sb wrap">
              <FormGroup>
                <label className="required" htmlFor={`streets.${streetId}.workEnd`}>{t(`form.workEnd`, lang)}</label>
                <Field
                  id={`workEnd`}
                  name={`workEnd`}
                  placeholder={t(`form.workEnd`, lang)}
                  as="input"
                  type="date"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => handleValueChange('workEnd', e.target.value, setFieldValue)}
                  max={'2030-12-31'}
                  className={errors['date'] ? 'error' : ''}
                  defaultValue={getStreet()?.workEnd?.split('T')[0] || null}
                  value={dates.workEnd || getStreet()?.workEnd?.split('T')[0] || null}
                  min={dates.workStart || getStreet()?.workStart?.split('T')[0] || null}
                />
              </FormGroup>
            </div>
            <div className="row sb wrap">
              <FormGroup>
                <label className='required' htmlFor={`streets.${streetId}.requiredFundSource${lang}`}>{t(`form.requiredFundSource`, lang)}</label>
                <Field
                  id={`requiredFundSource${lang}`}
                  name={`requiredFundSource${lang}`}
                  placeholder={t(`form.requiredFundSource`, lang)}
                  onChange={(e: any) => handleValueChange(`requiredFundSource${lang}`, lowerAndTrim(e.target.value), setFieldValue)}
                  className={errors['requiredFundSource'] ? 'error' : ''}
                  value={getStreet()[`requiredFundSource${lang}`]}
                />
              </FormGroup>
            </div>
            <div className="row sb wrap">
              <FormGroup>
                <label className='required' htmlFor={`streets.${streetId}.requiredFundAmount`}>{t(`form.requiredFundAmount`, lang)}</label>
                <Field
                  id={`requiredFundAmount`}
                  name={`requiredFundAmount`}
                  placeholder={t(`form.requiredFundAmount`, lang)}
                  as={'input'}
                  type="number"
                  className={errors['requiredFundAmount'] ? 'error' : ''}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => handleValueChange('requiredFundAmount', e.target.value, setFieldValue)}
                  value={getStreet()?.requiredFundAmount}
                />
              </FormGroup>
            </div>

            <label className="required" htmlFor="">{t('form.requiredFundSource', lang)}</label>

            {getStreet()?.requiredFund && Object.keys(getStreet()?.requiredFund).map((key) => {
              return (
                <div className="row sb" key={key}>
                  <FormGroup>
                    <label htmlFor={`requiredFund.${key}`}>{getStreet()?.requiredFund[key][`label${lang}`]}</label>
                    <Field
                      id={`requiredFund.${key}.value`}
                      name={`requiredFund.${key}.value`}
                      value={getStreet()?.requiredFund[key].value}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => handleValueChange(`requiredFund.${key}.value`, e.target.value.replace(' ', ''), setFieldValue)}
                      className={errors[key] ? 'error' : ''}
                    />
                  </FormGroup>
                  <FooterButton variant='delete' type='button' onClick={() => onDelete(key)}>x</FooterButton>
                </div>
              )
            })}


            {tempFund[streetId]?.isAdding && <div className="row sb">
              <FormGroup>
                <input
                  type="text"
                  value={tempFund[streetId][`label${lang}`]}
                  onChange={(e) => setTempFund({ ...tempFund, [streetId]: { ...tempFund[streetId], [`label${lang}`]: lowerAndTrim(e.target.value) } })}
                  placeholder={t('source', lang)}
                  className={tempFund[streetId].labelError ? 'error' : ''}
                />
                <input
                  type="text"
                  value={tempFund[streetId].value}
                  onChange={(e) => setTempFund({ ...tempFund, [streetId]: { ...tempFund[streetId], value: e.target.value.replace(' ', '') } })}
                  placeholder={t('sum', lang)}
                  className={tempFund[streetId].valueError ? 'error' : ''}
                />
              </FormGroup>
            </div>}

            <div className="add-info__buttons">
              {tempFund[streetId]?.isAdding}
              {tempFund[streetId]?.isAdding
                ? <>
                  <FormBlockButton onClick={() => onAdd(streetId)}>{t('save', lang)}</FormBlockButton>
                  <FormBlockButton onClick={handleCancelAdd}>{t('cancel', lang)}</FormBlockButton>
                </>
                : <FormBlockButton onClick={() => setTempFund({ ...tempFund, [streetId]: { ...tempFund[streetId], isAdding: true } })}>
                  <AddField /> {t('form.add_fund', lang)}
                </FormBlockButton>
              }
            </div>
          </>
        }
      </FormBlock>
    </>
  )
}

export default StreetDocumentationForm