import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';

import YearsForm from '../../forms/analytics/years.form';
import SingleYearForm from '../../forms/analytics/single-year.form';

import { getMarkers } from '../../requests/kato.request';
import { getFilteredRegionList } from '../../requests/supervisor.request';
import { deleteAnalyticsObject, updateAnalyticsObject } from '../../requests/supervisor.request';
import { getAnalytics } from '../../requests/analytics.request';

import { Save } from '../../assets/icons';
import { Footer, FooterButton, FormGroup, Wrapper } from '../../components/admin-page/admin-page.styles';

import { DiagramType, OptionType } from '../../interfaces/snp.interface';
import { diagramTypes } from '../../constants/snp.constant';
import { ToastContainer, toast } from 'react-toastify';
import { getLang, unique } from '../../utils/helpers.utils';
import { analyticsFields } from '../../constants/analytics.constant';

const AnalyticsPage = () => {
  const { t, i18n: { language } } = useTranslation();
  const [regions, setRegions] = useState<OptionType[]>([]);
  const [snps, setSnps] = useState<OptionType[]>([]);
  const [region, setRegion] = useState<number>(0);
  const [snp, setSnp] = useState<number>(0);
  const [diagramType, setDiagramType] = useState<DiagramType>();
  const [formData, setFormData] = useState<any[]>([]);
  const [content, setContent] = useState<JSX.Element>(<></>);

  const handleRegionChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setRegion(+e.target.value);
    getMarkers(+e.target.value).then((res) => {
      const response = res.map((item) => ({ value: item.code, label: item.nameRu, labelKz: item.nameKz, }))
      setSnps(response);
      setSnp(response[0].value);
    });
  }

  const handleSnpChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setSnp(+e.target.value)
  }

  const handleDiagramTypeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setDiagramType(e.target.value as DiagramType);
    handleLoadForm(e.target.value as DiagramType)
  }

  const handleDeleteObject = useCallback((id: number) => {
    const type = diagramType && ['harvest', 'lifestock'].includes(diagramType) ? `AGRICULTURE_${diagramType?.toUpperCase()}` : diagramType;

    diagramType && type && deleteAnalyticsObject(type as DiagramType, id)
      .then(res => {
        toast.success('Данные успешно удалены');
        getAnalytics(diagramType, snp).then((res) => {
          setFormData(res)
        });
      })
  }, [diagramType, snp])

  const handleLoadForm = useCallback((type: DiagramType) => {
    switch (type) {
      case 'population':
      case 'nationality':
      case 'studying':
      case 'harvest':
      case 'lifestock':
      case 'finance':
        setContent(<YearsForm
          formData={formData}
          onFormDataChange={setFormData}
          fields={analyticsFields[type]}
        />);
        break;
      case 'employment':
      case 'education':
      case 'healthcare':
      case 'road':
        setContent(<SingleYearForm
          formData={formData}
          onFormDataChange={setFormData}
          fields={analyticsFields[type]}
          onDelete={handleDeleteObject}
        />);
        break
      default:
        break;
    }
  }, [formData, handleDeleteObject])


  const renderSelects = (lang: 'Ru' | 'Kz' = 'Ru') => {
    return (
      <div className="grid-item">
        <FormGroup>
          <label htmlFor="region">{t(`form.region.name`, lang)}</label>
          <select value={region} onChange={handleRegionChange} disabled={lang.toLowerCase() !== language}>
            {regions.map((item) => <option key={item.value} value={item.value}>{lang === 'Kz' ? item.labelKz : item.label}</option>)}
          </select>
        </FormGroup>
        <FormGroup>
          <label htmlFor="snp">{t(`form.snp.name`, lang)}</label>
          <select value={snp} onChange={handleSnpChange} disabled={lang.toLowerCase() !== language}>
            {snps.map((item) => <option key={item.value} value={item.value}>{lang === 'Kz' ? item.labelKz : item.label}</option>)}
          </select>
        </FormGroup>

        <FormGroup>
          <label htmlFor="diagramType">{t(`diagramType.name`, lang)}</label>
          <select value={diagramType} onChange={handleDiagramTypeChange} defaultValue={""}>
            <option hidden value={""}></option>
            {diagramTypes.map((item) => <option key={item} value={item}>{t(`diagramType.${item}`)}</option>)}
          </select>
        </FormGroup>
      </div>
    )
  }

  const handleSave = () => {

    const updatedFormData = formData
      .map((item) => {
        const updatedItem = { ...item, kato: snp };
        for (let key in updatedItem) {
          if (updatedItem[key] === 0) {
            updatedItem[key] = null;
          }
        }
        return updatedItem;
      })

    setFormData(unique(updatedFormData, ['year', 'kato']));

    diagramType && updateAnalyticsObject(diagramType, updatedFormData)
      .then((res: any) => {
        setFormData(res.list);
        toast.success('Данные успешно сохранены')
      })
  }

  useEffect(() => {
    diagramType && getAnalytics(diagramType, snp).then((res) => {
      setFormData(res)
    });
  }, [diagramType, snp]);

  useEffect(() => {
    diagramType && handleLoadForm(diagramType)
  }, [formData, diagramType, handleLoadForm]);


  useEffect(() => {
    const snpItem: { kato: number, ppKato: number } = JSON.parse(localStorage.getItem('snp') as string);
    const ppKato = snpItem?.ppKato;
    const kato = snpItem?.kato;
    getFilteredRegionList().then((regionRes) => {
      const items = regionRes.list.map((item) => ({ value: item.kato, label: item.nameRu, labelKz: item.nameKz }))
      setRegions(items);
      setRegion(ppKato ? ppKato : items[0].value);

      getMarkers(ppKato ? ppKato : items[0].value).then((snpRes) => {
        const villages = snpRes.map((item) => ({ value: item.code, label: item.nameRu, labelKz: item.nameKz }))
        setSnps(villages);
        setSnp(kato ? kato : villages[0].value);
      });
    });
  }, []);

  return (
    <Wrapper>
      <Formik initialValues={{}}
        onSubmit={() => { }}
      >
        <Form className='flex'>
          {renderSelects(getLang())}
          <div className="grid-item">
            {content}
          </div>

          <Footer>
            <div className="buttons">
              <FooterButton variant="save" type='submit' onClick={handleSave}><Save /> {t('save')}</FooterButton>
            </div>
          </Footer>
        </Form>
      </Formik>

      <ToastContainer />
    </Wrapper>
  )
}

export default AnalyticsPage