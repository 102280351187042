import React, { useState } from 'react'
import DistrictItem from './district-item.component';

import { ArrowDown } from '../../assets/icons';
import { ReactComponent as DownloadIcon } from '../../assets/icons/download.svg'

import { RegionWrapper } from './index.styles';
import { ISettlement, ISnp } from '../../interfaces/snp.interface';
import { useTranslation } from 'react-i18next';

interface IProps {
  region: ISettlement;
  onClick: (item: ISnp) => void;
  onDistrictClick: (item: ISettlement) => void;
  onDownloadClick: (kato: number, name: string) => void;
}

const RegionItem: React.FC<IProps> = ({ region, onClick, onDistrictClick, onDownloadClick }) => {
  const { t, i18n: { language } } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)

  const toggleOpen = () => {
    setIsOpen(!isOpen)
  }

  return (
    <RegionWrapper isOpen={isOpen} lg={language}>
      <div className="name" >
        <ArrowDown onClick={toggleOpen} className="pointer" />
        <span className='primary pointer' onClick={toggleOpen}>{region.name}</span>
        <span className='secondary first'>{t('totalVillages')}: {region.total}</span>
        <span className='secondary second'>{t('inProcessVillages')}: {region.inProcess}</span>
        <span className='secondary third'>{t('completedVillages')}: {region.completed}</span>
        <span className='secondary fourth'>{t('requiredVillages')}: {(region.required)}</span>
        <span className='secondary'>{t('activePercentage')}: {isNaN(region.completionPercent) ? 0 : `${region.completionPercent.toFixed(2)}`}%</span>
        <DownloadIcon onClick={() => onDownloadClick(region.kato, region.name)} className='download' />
      </div>
      {isOpen && region.children &&
        <div className='districts'>
          {
            region.children.sort((a, b) => a.name.localeCompare(b.name)).map((item) =>
              <DistrictItem key={item.name} district={item} onClick={onClick} onDistrictClick={onDistrictClick} />)
          }
        </div>
      }
    </RegionWrapper>
  )
}

export default RegionItem