import React from 'react'
import styled from 'styled-components'
import { Outlet } from 'react-router-dom'


const StyledBackground = styled.div`
  min-height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  position: relative;
  padding: 0.625rem;
`


const BasicLayout = () => {

  return (
    <StyledBackground>
      <Outlet />
    </StyledBackground>

  )
}

export default BasicLayout