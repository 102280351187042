import React from 'react'
import { Bar } from "react-chartjs-2";
import { horizontalBarChartOptions, horizontalBarChartData } from '../../constants/chart.constant';

interface IProps {
  labels: string[],
  data: number[]
}

const HorizontalBarChart: React.FC<IProps> = ({ labels, data }) => {
  return (
    <Bar data={horizontalBarChartData(labels, data)} options={horizontalBarChartOptions} width={220} height={50}/>
  )
}

export default HorizontalBarChart