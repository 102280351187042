import React from 'react'
import { Bar } from 'react-chartjs-2'
import { barChartData, barChartOptions } from '../../constants/chart.constant'

interface IProps {
  labels: string[],
  datasets: any[],
  total?: number,
  format?: boolean,
  showLabels?: boolean
}


const BarChart: React.FC<IProps> = ({ labels, datasets, format = false, showLabels = true }) => {
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <Bar data={barChartData(labels, datasets)} options={barChartOptions(format, showLabels)} />
    </div>
  )
}

export default BarChart