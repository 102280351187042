import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik, Field } from 'formik';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

import Modal from '../modal-component';
import { getImageUrl } from '../right-menu/education.component';

import {
  AddField,
  DeleteX,
  EditPen,
  Save,
  GoUp,
  Trash
} from '../../assets/icons';

import {
  Wrapper,
  FormBlock,
  FormGroup,
  Footer,
  FooterButton
} from './admin-page.styles';

import {
  IGeneralInfo,
  IProject,
  OfficialRoleType,
  OptionType
} from '../../interfaces/snp.interface';

import { getMarkers, getSettlementTree } from '../../requests/kato.request';
import { getGeneralInfo } from '../../requests/snp.request';
import {
  deleteImage,
  updateForm,
  uploadGeneralImage,
  changeStatus,
  deleteAll,
  getFilteredRegionList
} from '../../requests/supervisor.request';

import { options, roadCoverOptions } from '../../constants/snp.constant';
import { capitalize, checkVal, getLang, keepCapitalized, lowerAndTrim, t } from '../../utils/helpers.utils'

const initialFormData: IGeneralInfo = {
  kato: 0,
  supervisor: {
    fullNameRu: '',
    fullNameKz: '',
    positionRu: null,
    positionKz: null,
    phoneNumber: '',
    inPositionFrom: '2023-09-05T04:49:54.252Z',
    photoId: null,
  },
  police: {
    fullNameRu: '',
    fullNameKz: '',
    positionRu: null,
    positionKz: null,
    phoneNumber: '',
    inPositionFrom: '2023-09-05T04:49:54.252Z',
    photoId: null,
  },
  population: {
    all: 0,
    kazakhs: 0,
    russians: 0,
    others: 0,
  },
  employment: {
    employed: 0,
    selfEmployed: 0,
    notEmployed: 0,
    acpReceivers: 0,
  },
  buildingNumber: 0,
  regionRoad: {
    distance: 0,
    cover: null,
    quality: null,
  },
  areaRoad: {
    distance: 0,
    cover: null,
    quality: null,
  },
};

const supervisorValToError: { [key: string]: string } = {
  fullNameRu: 'nameS',
  fullNameKz: 'nameS',
  positionRu: 'positionS',
  positionKz: 'positionS',
  phoneNumber: 'phoneS',
  inPositionFrom: 'inPositionFromS',
  photoId: 'photoS',
  addressKz: 'addressS',
  addressRu: 'addressS',
}
const policeValToError: { [key: string]: string } = {
  fullNameRu: 'nameP',
  fullNameKz: 'nameP',
  positionRu: 'positionP',
  positionKz: 'positionP',
  phoneNumber: 'phoneP',
  inPositionFrom: 'inPositionFromP',
  photoId: 'photoP',
  addressKz: 'addressP',
  addressRu: 'addressP',
}

const GeneralInfo = () => {
  const { i18n: { language } } = useTranslation();
  const navigate = useNavigate();
  const { kato } = useParams();

  const formikRef = useRef<any>(null);
  const wrapperRef = useRef<any>(null);

  const [regions, setRegions] = useState<OptionType[]>([]);
  const [snps, setSnps] = useState<OptionType[]>([]);
  const [region, setRegion] = useState<number>(0);
  const [snp, setSnp] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [selectedRole, setSelectedRole] = useState<OfficialRoleType | null>(null);
  const [selectedImageName, setSelectedImageName] = useState('');
  const [formData, setFormData] = useState<any>(initialFormData);
  const [isFinished, setIsFinished] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [photoIds, setPhotoIds] = useState<any>({
    p: null,
    s: null,
  });


  const handleRegionChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setRegion(+e.target.value);
    getMarkers(+e.target.value).then((res) => {
      const response = res.map((item) => ({ value: item.code, label: item.nameRu, labelKz: item.nameKz, }))
      setSnps(response);
      setSnp(response[0].value);
    });
  }

  const handleSnpChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setSnp(+e.target.value)
    navigate(`/admin/${e.target.value}`)
  }

  const handleSubmitForm = (values: IProject) => {
    setErrors({});

    const valuesToSubmit: any = {
      ...values,
      supervisor: {
        ...values.supervisor,
        fullNameKz: capitalize(values.supervisor.fullNameKz) || null,
        fullNameRu: capitalize(values.supervisor.fullNameRu) || null,
        positionKz: keepCapitalized(values.supervisor.positionKz) || null,
        positionRu: keepCapitalized(values.supervisor.positionRu) || null,
      },
      police: {
        ...values.police,
        fullNameKz: capitalize(values.police.fullNameKz) || null,
        fullNameRu: capitalize(values.police.fullNameRu) || null,
        positionKz: keepCapitalized(values.police.positionKz) || null,
        positionRu: keepCapitalized(values.police.positionRu) || null,
      },
    }

    const keys = Object.keys(valuesToSubmit.supervisor).filter(key => !['personalPhoneNumber', 'photoId'].includes(key));

    for (const key of keys) {
      if (!checkVal(valuesToSubmit.supervisor[key]) || valuesToSubmit.supervisor[key] === "") {
        setErrors((prev: any) => ({ ...prev, [supervisorValToError[key]]: true }));
        toast.error(t(`errors.${supervisorValToError[key]}`, language));
        return
      }
    }

    if (!checkVal(photoIds.s)) {
      setErrors((prev: any) => ({ ...prev, [supervisorValToError.photoId]: true }));
      toast.error(t(`errors.${supervisorValToError.photoId}`, language));
      return
    }

    const policeKeys = Object.keys(valuesToSubmit.police).filter(key => !['personalPhoneNumber', 'photoId'].includes(key));

    if (checkVal(valuesToSubmit.police.fullNameRu) || checkVal(valuesToSubmit.police.fullNameKz)) {
      for (const key of policeKeys) {
        if (!checkVal(valuesToSubmit.police[key]) || valuesToSubmit.police[key] === "") {
          setErrors((prev: any) => ({ ...prev, [policeValToError[key]]: true }));
          toast.error(t(`errors.${policeValToError[key]}`, language));
          return;
        }
      }

      if (!checkVal(photoIds.p)) {
        setErrors((prev: any) => ({ ...prev, [policeValToError.photoId]: true }));
        toast.error(t(`errors.${policeValToError.photoId}`, language));
        return;
      }
    }

    const populationKeys = Object.keys(valuesToSubmit.population);

    for (const key of populationKeys) {
      if (!checkVal(valuesToSubmit.population[key]) || valuesToSubmit.population[key] === "") {
        if (key !== 'all' && +valuesToSubmit.population[key] !== 0) {
          setErrors((prev: any) => ({ ...prev, population: true }));
          toast.error(t(`errors.population`, language));
          return;
        }
      }
    }

    const employmentKeys = Object.keys(valuesToSubmit.employment);

    for (const key of employmentKeys) {
      if (!checkVal(valuesToSubmit.employment[key]) || valuesToSubmit.employment[key] === "") {
        if (!['employed'].includes(key)) {
          if (+valuesToSubmit.employment[key] !== 0) {
            setErrors((prev: any) => ({ ...prev, employment: true }));
            toast.error(t(`errors.employment`, language));
            return;
          }
        } else {
          setErrors((prev: any) => ({ ...prev, employment: true }));
          toast.error(t(`errors.employment`, language));
          return;
        }
      }
    }

    if (!checkVal(valuesToSubmit.buildingNumber)) {
      if (valuesToSubmit.buildingNumber !== 0) {
        setErrors((prev: any) => ({ ...prev, buildingNumber: true }));
        toast.error(t(`errors.buildingNumber`, language));
        return;
      }
    }

    const regionRoadKeys = Object.keys(valuesToSubmit.regionRoad);
    const areaRoadKeys = Object.keys(valuesToSubmit.areaRoad);

    for (const key of regionRoadKeys) {
      if (!checkVal(valuesToSubmit.regionRoad[key]) || valuesToSubmit.regionRoad[key] === "") {
        if (+valuesToSubmit.regionRoad[key] !== 0) {
          setErrors((prev: any) => ({ ...prev, roads: true }));
          toast.error(t(`errors.roads`, language));
          return;
        }
      }
    }

    for (const key of areaRoadKeys) {
      if (!checkVal(valuesToSubmit.areaRoad[key]) || valuesToSubmit.areaRoad[key] === "") {
        if (+valuesToSubmit.areaRoad[key] !== 0) {
          setErrors((prev: any) => ({ ...prev, roads: true }));
          toast.error(t(`errors.roads`, language));
          return;
        }
      }
    }

    updateForm(valuesToSubmit)
      .then((res) => {
        toast.success(t('toast.save_success', language))
        loadForm();
      })
      .catch((err) => toast.error(t('toast.save_error')));
  }

  const handleSelectRole = (role: OfficialRoleType) => {
    setSelectedRole(role);
    openPhotoModal()
  }

  const handleImageUpload = async () => {
    if (!selectedImage) {
      return;
    }

    selectedRole && uploadGeneralImage(snp, selectedRole, selectedImage)
      .then(_ => {
        loadForm();
        closePhotoModal();
      })
      .catch(() => toast.error(t('errors.photoResolution2')));

  };

  const handleDeleteImage = (imageId: number) => {
    deleteImage(imageId).then(_ => loadForm())
  };

  const handleImageSelect = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      setSelectedImageName(file.name || '');
      setSelectedImage(file);
    }
  };

  const handleDelete = () => {
    setIsConfirmOpen(true);
  }

  const handleGoUp = () => {
    if (wrapperRef && wrapperRef.current) {
      wrapperRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setIsFinished(true);
      changeStatus(snp, 'COMPLETED').then(res => {
        formikRef.current.setValues({ ...formikRef.current.values, status: 'COMPLETED' })
        handleSubmitForm({ ...formikRef.current.values, status: 'COMPLETED' })
      })
    } else {
      setIsFinished(false);
      changeStatus(snp, 'IN_PROCESS').then(res => {
        formikRef.current.setValues({ ...formikRef.current.values, status: 'IN_PROCESS' })
        handleSubmitForm({ ...formikRef.current.values, status: 'IN_PROCESS' })
      })
    }
  }

  const openPhotoModal = () => {
    setIsOpen(true);
  };

  const closePhotoModal = () => {
    setIsOpen(false);
  };

  const confirmDelete = () => {
    deleteAll(snp).then(() => navigate('/admin/snp'))
  }

  const loadForm = useCallback(() => {
    const val = kato ? kato : snp
    val && getGeneralInfo(+val).then((res) => {
      const data = {
        ...res,
        supervisor: {
          ...res.supervisor,
          inPositionFrom: res.supervisor.inPositionFrom ? res.supervisor.inPositionFrom.split('T')[0] : '',
        },
        police: {
          ...res.police,
          inPositionFrom: res.police.inPositionFrom ? res.police.inPositionFrom.split('T')[0] : '',
        },
      }

      setPhotoIds({ p: data?.police?.photoId, s: data?.supervisor?.photoId });
      const status = res.status;
      setIsFinished(status === 'COMPLETED');
      setFormData(data);
      setIsLoading(false);

      localStorage.setItem('snp', JSON.stringify({ kato: val }));
    });
  }, [region, snp, kato])

  useEffect(() => {
    const snpItem: { kato: number, ppKato: number } = JSON.parse(localStorage.getItem('snp') as string);
    const val = kato ? kato : snpItem.kato;
    getSettlementTree().then((res) => {
      const children = Object.values(res.data)
        .flatMap((item) => Object.values(item).flatMap(item => item))
      const found = children.find((item) => item.kato === +val)

      if (found) {
        getFilteredRegionList().then((regionRes) => {
          const items = regionRes.list.map((item) => ({ value: item.kato, label: item.nameRu, labelKz: item.nameKz }))
          setRegions(items);
          setRegion(found.ppKato || 0);
          setSnp(+val);
          getMarkers(found.ppKato || 0).then((snpRes) => {
            const villages = snpRes.map((item) => ({ value: item.code, label: item.nameRu, labelKz: item.nameKz }))
            setSnps(villages);
          });
        });
      }
    });
  }, []);

  const isDisabled = (type: string) => type === 'police' && !checkVal(formData.police.fullNameRu) && !checkVal(formData.police.fullNameKz)

  useEffect(() => {
    loadForm();
  }, [loadForm, snp]);

  const renderSelects = (lang: 'Ru' | 'Kz' = 'Ru') => {
    return (
      <div className="grid-item">
        <FormGroup>
          <label htmlFor="region">{t(`form.region.name`, lang)}</label>
          <Field as="select" value={region} onChange={handleRegionChange} disabled={lang.toLowerCase() !== language}>
            {regions.map((item) => <option key={item.value} value={item.value}>{lang === 'Kz' ? item.labelKz : item.label}</option>)}
          </Field>
        </FormGroup>
        <FormGroup>
          <label htmlFor="snp">{t(`form.snp.name`, lang)}</label>
          <Field as="select" value={snp} onChange={handleSnpChange} disabled={lang.toLowerCase() !== language}>
            {snps.map((item) => <option key={item.value} value={item.value}>{lang === 'Kz' ? item.labelKz : item.label}</option>)}
          </Field>
        </FormGroup>
      </div>
    )
  }

  const renderFields = (lang: 'Ru' | 'Kz' = 'Ru', setFieldValue: any) => {
    return (
      <div className="grid-item">
        {['supervisor', 'police'].map((item) => (
          <FormBlock type='gray' key={item}>
            <div className="title">{t(`form.${item}`, lang)}</div>
            <FormGroup>
              <label
                htmlFor={`${item}.fullName${lang}`}
                className={item === 'supervisor' ? 'required' : ''}
              >
                {t(`form.${item}Obj.fullName`, lang)}
              </label>
              <Field
                name={`${item}.fullName${lang}`}
                type="text"
                className={`capitalize ${errors[`name${item[0].toUpperCase()}`] ? 'error' : ''}`}
                onChange={(e: any) => {
                  setFieldValue(`${item}.fullName${lang}`, capitalize(e.target.value))
                  setFormData({ ...formData, [item]: { ...formData[item], [`fullName${lang}`]: capitalize(e.target.value) } })
                }}
              />
            </FormGroup>
            <FormGroup>
              <label
                htmlFor={`${item}.position${lang}`}
                className={isDisabled(item) ? '' : 'required'}
              >
                {t(`form.${item}Obj.position`, lang)}
              </label>
              <Field
                name={`${item}.position${lang}`}
                type="text"
                onChange={(e: any) => setFieldValue(`${item}.position${lang}`, keepCapitalized(e.target.value))}
                disabled={isDisabled(item)}
                className={`${errors[`position${item[0].toUpperCase()}`] ? 'error' : ''}`}
              />
            </FormGroup>
            <div className="row">
              <FormGroup>
                <label
                  htmlFor={`${item}.inPositionFrom`}
                  className={isDisabled(item) ? '' : 'required'}
                >
                  {t(`form.${item}Obj.inPositionFrom`, lang)}
                </label>
                <Field
                  name={`${item}.inPositionFrom`}
                  type="date"
                  as='input'
                  disabled={isDisabled(item)}
                  className={`${errors[`inPositionFrom${item[0].toUpperCase()}`] ? 'error' : ''}`}
                />
              </FormGroup>
              <FormGroup>
                <label
                  htmlFor={`${item}.phoneNumber`}
                  className={item === 'supervisor' ? 'required' : ''}
                >
                  {t(`form.${item}Obj.phoneNumber`, lang)}
                </label>
                <Field
                  name={`${item}.phoneNumber`}
                  as="input"
                  type="number"
                  disabled={isDisabled(item)}
                  className={`${errors[`phone${item[0].toUpperCase()}`] ? 'error' : ''}`}
                />
              </FormGroup>
            </div>

            <FormGroup>
              <label
                className={item === 'supervisor' || !isDisabled(item) ? 'required' : ''}
                htmlFor={`${item}.address${lang}`}
              >
                {t(`form.${item}Obj.address`, lang)}
              </label>
              <Field
                name={`${item}.address${lang}`}
                type="text"
                onChange={(e: any) => setFieldValue(`${item}.address${lang}`, keepCapitalized(e.target.value))}
                className={`${errors[`address${item[0].toUpperCase()}`] ? 'error' : ''}`}
                disabled={isDisabled(item)}
                placeholder={item === 'supervisor' || !isDisabled(item) ? t(`placeholders.address`, lang) : ''}
              />
            </FormGroup>


            {item === 'supervisor' && <FormGroup>
              <div style={{ display: 'flex', alignItems: 'center', gap: '0.625rem' }}>
                <label htmlFor="wonElection">{t(`form.${item}Obj.wonElection`, lang)}</label>
                <Field
                  name={`wonElection`}
                  as="input"
                  type="checkbox"
                  checked={formData.wonElection || false}
                  onChange={(e: any) => {
                    setFieldValue(`wonElection`, e.target.checked)
                    setFormData({ ...formData, wonElection: e.target.checked })
                  }}
                />
              </div>

            </FormGroup>
            }

            {
              lang.toLowerCase() === language && <FormGroup>
                <div className="photo">
                  <label className={item === 'supervisor' ? 'required' : ''} htmlFor={`${item}.photoId`}>{t(`form.${item}Obj.photoId`, lang)}</label>
                  <div className={`image ${errors[`photo${item[0].toUpperCase()}`] ? 'error' : ''} `}>
                    {formData[item].photoId
                      ? <>
                        <img src={getImageUrl(formData[item].photoId)} alt="person" />
                        <div className="buttons">
                          <div className='edit' onClick={() => handleSelectRole(item.toUpperCase() as OfficialRoleType)}><EditPen /> {t('form.photo.edit', language)}</div>
                          <div className='delete' onClick={() => handleDeleteImage(+formData[item].photoId)} ><DeleteX /> {t('form.photo.delete', language)}</div>
                        </div>
                      </>
                      : <div className="buttons">
                        <div className='upload' onClick={() => handleSelectRole(item.toUpperCase() as OfficialRoleType)}> <AddField /> {t('form.photo.upload', language)}</div>
                      </div>}
                  </div>
                </div>
              </FormGroup>
            }
          </FormBlock>
        ))
        }

        {
          // lang.toLowerCase() === language && ['population', 'employment'].map((item) => (
          lang.toLowerCase() === language && ['employment'].map((item) => (
            <FormBlock type='white' key={item}>
              <div className="title">{t(`form.${item}`, lang)}</div>
              <div className="grid">
                {Object.keys(formData[item]).map((key) => (
                  <FormGroup key={key} inputText={t(`input-text.${item}`, lang)}>
                    <label className='required' htmlFor={`${item}.${key}`}>{t(`form.${item}Obj.${key}`, lang)}</label>
                    <Field
                      className={`${errors[`${item}`] ? 'error' : ''}`}
                      name={`${item}.${key}`} as="input" type="number"
                      disabled={key === 'all'}
                      value={key !== 'all' ? formData[item][key] : formData && formData[item] && Object.keys(formData[item]).filter((key) => key !== 'all').reduce((acc, key) => acc + +formData[item][key], 0)}
                      onChange={(e: any) => {
                        setFieldValue(`${item}.${key}`, e.target.value)
                        setFormData({ ...formData, [item]: { ...formData[item], [key]: e.target.value } })
                      }}
                    />
                  </FormGroup>
                ))}
              </div>
            </FormBlock>
          ))
        }

        {
          lang.toLowerCase() === language && <>
            <FormGroup>
              <div className="building">
                <label className='required' htmlFor="buildingNumber">{t(`form.buildingNumber`, lang)}</label>
                <Field className={`${errors[`buildingNumber`] ? 'error' : ''}`} name="buildingNumber" as="input" type="number" />
              </div>
            </FormGroup>

            <FormBlock type='white'>
              <div className="title">{t(`form.roadsTitle`, lang)}</div>
              <div className="grid">
                <div className='roads'>
                  <FormGroup key={'regionRoad.distance'} inputText={t(`input-text.regionRoad`, lang)}>
                    <label className='required' htmlFor={`regionRoad.distance`}>{t(`form.regionRoad.distance`, lang)}</label>
                    <Field name={`regionRoad.distance`} as="input" type="number" className={`${errors['roads'] ? 'error' : ''}`} />
                  </FormGroup>
                  <FormGroup key={'quality'}>
                    <label className='required' htmlFor={`regionRoad.quality`}>{t(`form.regionRoad.quality`, lang)}</label>
                    <Field name={`regionRoad.quality`} as="select" className={`${errors['roads'] ? 'error' : ''}`}>
                      <option value="" selected hidden></option>
                      {options.map(option => <option value={option.value}>{t(`form.roads.${option.label}`, lang)}</option>)}
                    </Field>
                  </FormGroup>
                  <FormGroup key={'cover'}>
                    <label className='required' htmlFor={`regionRoad.cover`}>{t(`form.regionRoad.cover`, lang)}</label>
                    <Field name={`regionRoad.cover`} as="select" className={`${errors['roads'] ? 'error' : ''}`}>
                      <option value="" selected hidden></option>
                      {roadCoverOptions.map(option => <option value={option.value}>{t(`form.roads.${option.label}`, lang)}</option>)}
                    </Field>
                  </FormGroup>
                </div>

                <div className='roads'>
                  <FormGroup key={'distance'} inputText={t(`input-text.areaRoad`, lang)}>
                    <label className='required' htmlFor={`areaRoad.distance`}>{t(`form.areaRoad.distance`, lang)}</label>
                    <Field name={`areaRoad.distance`} as="input" type="number" className={`${errors['roads'] ? 'error' : ''}`} />
                  </FormGroup>
                  <FormGroup key={'quality'}>
                    <label className='required' htmlFor={`areaRoad.quality`}>{t(`form.areaRoad.quality`, lang)}</label>
                    <Field name={`areaRoad.quality`} as="select" className={`${errors['roads'] ? 'error' : ''}`}>
                      <option value="" selected hidden></option>
                      {options.map(option => <option value={option.value}>{t(`form.roads.${option.label}`, lang)}</option>)}
                    </Field>
                  </FormGroup>
                  <FormGroup key={'cover'}>
                    <label className='required' htmlFor={`areaRoad.cover`}>{t(`form.areaRoad.cover`, lang)}</label>
                    <Field name={`areaRoad.cover`} as="select" className={`${errors['roads'] ? 'error' : ''}`}>
                      <option value="" selected hidden></option>
                      {roadCoverOptions.map(option => <option value={option.value}>{t(`form.roads.${option.label}`, lang)}</option>)}
                    </Field>
                  </FormGroup>
                </div>
              </div>
            </FormBlock>
          </>
        }
      </div >
    )
  }

  return (
    <div style={{ position: 'relative' }}>
      {
        !isLoading && formData.kato && <>
          <Wrapper ref={wrapperRef}>
            <Formik
              initialValues={formData}
              onSubmit={(values) => {
                handleSubmitForm(values);;
              }}
              innerRef={formikRef}
            >
              {({ setFieldValue }) => (
                <Form>
                  {renderSelects(getLang())}
                  {renderSelects(getLang() !== 'Kz' ? 'Kz' : 'Ru')}
                  {renderFields(getLang(), setFieldValue)}
                  {renderFields(getLang() !== 'Kz' ? 'Kz' : 'Ru', setFieldValue)}
                  <Footer>
                    <div className="buttons">
                      <FooterButton type='submit' variant="save"><Save /> {t('save', language)}</FooterButton>
                      <div className='checkbox'>
                        <input type="checkbox" name="is-finished" id="is-finished" checked={isFinished} onChange={handleCheckboxChange} />
                        <label htmlFor="is-finished">{t('village-is-finished', language)}</label>
                      </div>
                    </div>
                    <div className="buttons">
                      <FooterButton variant="delete" type='button' onClick={handleDelete}><Trash width={16} /> {t('delete-all', language)}</FooterButton>
                      <FooterButton variant="go-up" type='button' onClick={handleGoUp}><GoUp /> {t('go-up', language)}</FooterButton>
                    </div>
                  </Footer>
                </Form>
              )}
            </Formik>
          </Wrapper>

          <Modal isFileUpload isOpen={isOpen} onClose={closePhotoModal}>
            <label className="input-file">
              <span className="input-file-text">{selectedImageName}</span>
              <input type="file"
                accept="image/*"
                id="imageInput"
                onChange={handleImageSelect} />
              <span className="input-file-btn">{t('choose-file', language)}</span>
            </label>
            <button className="upload-button" onClick={handleImageUpload}>
              {t('form.photo.upload', language)}
            </button>
          </Modal>
          <Modal isFileUpload isOpen={isConfirmOpen} onClose={() => setIsConfirmOpen(false)}>
            <h1>{t('confirm-delete-village', language)}</h1>
            <div className="buttons">
              <FooterButton variant="delete" type='button' onClick={confirmDelete}><Trash width={16} /> {t('form.requirementsObj.YES', language)}</FooterButton>
              <FooterButton variant="cancel" type='button' onClick={() => setIsConfirmOpen(false)}> {t('form.requirementsObj.NO', language)}</FooterButton>
            </div>
          </Modal>
        </>
      }
      <ToastContainer />
    </div>
  )
}

export default GeneralInfo