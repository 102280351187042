import React from 'react';
import { Line } from 'react-chartjs-2'

import { lineChartData, lineChartOptions } from '../../constants/chart.constant';

interface IProps {
  labels: string[];
  datasets: any[];
  forwardedRef?: any;
}

const LineChart: React.FC<IProps> = ({ labels, datasets, forwardedRef }) => {
  return (
    <Line data={lineChartData(labels, datasets)} options={lineChartOptions(datasets.length > 0)} ref={forwardedRef} />
  )
}

export default React.forwardRef(({ labels, datasets }: any, ref) => <LineChart forwardedRef={ref} labels={labels} datasets={datasets} />);
