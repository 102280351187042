import { createContext, useContext, useEffect, useState } from "react";

export type UserContextType = {
  user: any;
  setUser: (user: any) => void;
};

interface IProps {
  children: React.ReactNode;
}

const UserContext = createContext<UserContextType>({
  user: null,
  setUser: () => { },
});

export const UserProvider: React.FC<IProps> = ({ children }) => {
  const [user, setUser] = useState<any>(null);

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user) {
      setUser(JSON.parse(user));
    }
  }, []);

  return <UserContext.Provider value={{ user, setUser }}>{children}</UserContext.Provider>;
};

export const useUserContext = (): UserContextType => useContext(UserContext);
