const analyticsFields = {
  population: ['populationCount'],
  nationality: ['kazakhs', 'russians', 'others'],
  employment: ['employed', 'selfEmployed', 'acpReceivers', 'notEmployed'],
  education: ['power', 'fact'],
  healthcare: ['power', 'fact'],
  studying: ['studyingCount', 'graduatedCount'],
  road: ['totalStreetCount', 'total', 'inGoodCondition', 'inSatisfactoryCondition', 'withLighting'],
  harvest: ['grainCropCount', 'oilseedCount'],
  lifestock: ['krcCount', 'horseCount', 'mrcCount', 'pigCount'],
  finance: ['mio', 'shtpMemorandum', 'shtpProjectImpovement']
} 

export {
  analyticsFields
}