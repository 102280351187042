import React, { useCallback, useEffect, useState } from 'react'
import { IMarker } from '../../interfaces/snp.interface';
import { getGeneralInfo } from '../../requests/snp.request';
import { useTranslation } from 'react-i18next'
import { PassportWrapper } from './index.styles';
import { getImageUrl } from './education.component';
import { PhotoPlaceholder } from '../../assets/images';
import { formatDate, getLang } from '../../utils/helpers.utils';
import { DownloadIcon, Phone, Tick } from '../../assets/icons';
import ZoomableImageModal from './zoomable-image.component';

const Passport: React.FC<{ selectedMarker: IMarker | null }> = ({ selectedMarker }) => {
  const { t } = useTranslation();

  const [info, setInfo] = useState<any>();
  const [selectedImage, setSelectedImage] = useState<number | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const getInfo = useCallback(() => {
    selectedMarker && getGeneralInfo(selectedMarker?.code).then((data) => {
      const { police, supervisor, wonElection } = data;
      setInfo({ police, supervisor, wonElection });
    })
  }, [selectedMarker])

  const openImageModal = (image: any, images: any, name: string) => {
    setIsOpen(true);
    setSelectedImage(image);
  }

  const closeImageModal = () => {
    setIsOpen(false);
    setSelectedImage(null);
  }

  const handleDownload = (url: string) => {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'фото.png');
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  useEffect(() => {
    getInfo();
  }, [getInfo, selectedMarker])

  if (!selectedMarker) {
    return <h1>{t('no-data')}</h1>
  }

  return (
    <PassportWrapper>
      <div className="container police">
        <div className="item">
          <div className="photo-container">
            <img className='photo' src={(info?.supervisor.photoId && getImageUrl(+info?.supervisor.photoId)) || PhotoPlaceholder} alt='profile' onClick={() => info?.supervisor.photoId && openImageModal({ date: null, imageId: +info?.supervisor.photoId }, [], 'Фото')} />
            {info?.supervisor.photoId && <button className='download-button' onClick={() => handleDownload(getImageUrl(info?.supervisor.photoId))}><DownloadIcon /></button>}
          </div>

          <div className="info">
            <div className='group'>
              <div className='name'>{info?.supervisor[`fullName${getLang()}`]}</div>
              <div className='row'>
                <div className='position'>{info?.supervisor[`position${getLang()}`]}</div>
                <div className='works-from'>{t('people.worksFrom')}:
                  <span className='date'> {info?.supervisor.inPositionFrom && formatDate(info?.supervisor.inPositionFrom)}</span>
                </div>
              </div>
            </div>

            <div className='divider'></div>

            <div className="items">
              <div className='items__item'>
                <div className="phone row">
                  <Phone />
                  <a className='number' href={`tel:${info?.supervisor.phoneNumber}`}>{info?.supervisor.phoneNumber}</a>
                  <span className='other'>{t('reception')}</span>
                </div>
              </div>

              <div className='items__item'>
                <div className="phone row">
                  <span className='other'>{t('form.supervisorObj.address')}:</span>
                  <span className='other'>{info?.supervisor[`address${getLang()}`]}</span>
                </div>
              </div>

              {info?.wonElection ? <div className='items__item'>
                <div className="row">
                  <span className='with-icon'><Tick /> {t(`form.supervisorObj.wonElection`)}</span>
                </div>
              </div>
                : <></>
              }
            </div>
          </div>
        </div>
      </div>

      <div className="container police">
        <div className="item">
          <div className="photo-container">
            <img className='photo' src={(info?.police.photoId && getImageUrl(+info?.police.photoId)) || PhotoPlaceholder} alt='profile' onClick={() => info?.police.photoId && openImageModal({ date: null, imageId: +info?.police.photoId }, [], 'Фото')} />
            {info?.supervisor.photoId && <button className='download-button' onClick={() => handleDownload(getImageUrl(info?.supervisor.photoId))}><DownloadIcon /></button>}
          </div>

          <div className="info">
            <div className='group'>
              <div className='name'>{info?.police[`fullName${getLang()}`] ? info?.police[`fullName${getLang()}`] : t('form.policeObj.no')}</div>

              {!!info?.police[`fullName${getLang()}`] && <div className='row'>
                <div className='position'>{info?.police[`position${getLang()}`]}</div>
                <div className='works-from'>{t('people.worksFrom')}:
                  <span className='date'> {info?.police.inPositionFrom && formatDate(info?.police.inPositionFrom)}</span>
                </div>
              </div>}
            </div>
            {!!info?.police[`fullName${getLang()}`] &&
              <>
                <div className='divider'></div>

                <div className="items">
                  <div className='items__item'>
                    <div className="phone row">
                      <Phone />
                      <a className='number' href={`tel:${info?.police.phoneNumber}`}>{info?.police.phoneNumber}</a>
                      <span className='other'>{t('reception')}</span>
                    </div>
                  </div>

                  <div className='items__item'>
                    <div className="phone row">
                      <span className='other'>{t('form.supervisorObj.address')}:</span>
                      <span className='other'>{info?.police[`address${getLang()}`]}</span>
                    </div>
                  </div>
                </div>
              </>
            }
          </div>
        </div>
      </div >

      {selectedImage && (
        <ZoomableImageModal
          isFileUpload={true}
          isOpen={isOpen}
          onClose={closeImageModal}
          selectedImage={selectedImage}
          setSelectedImage={setSelectedImage}
          currentImageList={[]}
        />
      )}
    </PassportWrapper >
  )
}

export default Passport