import React, { FC, useEffect } from 'react'
import { checkVal, formatDate, getLang } from '../../utils/helpers.utils';
import { useTranslation } from 'react-i18next';
import { getImageUrl } from '../right-menu/education.component';
import { ArrowDown, DownloadIcon } from '../../assets/icons';
import { documentationKeys } from '../insfrastructureProjects/education-form.component';

interface IProps {
  street: any;
  openImageModal: (image: any, images: any, name: string) => void;
  isOpen: boolean,
  handleOpen: (id: any) => void
}
const docList = ['requiredFundSourceRu', 'requiredFundSourceKz', 'requiredFundAmount', 'workEnd', 'workStart', 'requiredFund'];
const defaultList = ['files', 'nameRu', 'id', 'nameKz', 'nameEn', 'images', 'requiredFundSourceRu', 'requiredFundSourceKz', 'requiredFundAmount', 'workEnd', 'workStart', 'requiredFund', ...docList];

const Street: FC<IProps> = ({ street, openImageModal, isOpen, handleOpen }) => {
  const { t } = useTranslation();
  const [skipList, setSkipList] = React.useState<any>(defaultList);

  const handleDownload = (url: string) => {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'фото.png');
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  useEffect(() => {
    if (street.repairRequired === true) {
      setSkipList(defaultList)
    } else {
      setSkipList([...defaultList, ...documentationKeys])
    }
  }, [street.id, street.repairRequired]);

  return (
    <div className="block" key={street.id}>
      <div className="subheading with-icon" onClick={() => handleOpen(street.id)}>
        <ArrowDown width="12" style={{ transform: isOpen ? 'rotate(180deg)' : 'rotate(270deg)' }} />
        {street[`name${getLang()}`] || t('street-name')}
      </div>

      {isOpen && (
        <>
          <ul>
            {Object.keys(street).map((section) => !skipList.includes(section) &&
              <>
                {
                  (street[section] || (!isNaN(street[section]) && street[section] > 0)) && <div key={section}>
                    {t(`form.${section}`).split(',')[0] + ': '}
                    <b>
                      {
                        ['documentationStatus', 'repairRequired', 'factCondition'].includes(section)
                          ? t(street[section]?.toString().toLowerCase())
                          : ['workStart', 'workEnd'].includes(section)
                            ? formatDate(street[section])
                            : street[section]
                      }
                    </b>
                    {section === 'totalStreetLength' && ' км'}
                  </div>
                }
              </>)
            }

            {
              (street.repairRequired === 'false' || street.repairRequired === false) && <div>
                {t('requirementsOptions.NOT_NEEDED')}
              </div>
            }

            {
              street.repairRequired && street.documentationStatus === 'HAVE' && <>
                {checkVal(street[`requiredFundSource${getLang()}`]) && <div>
                  {t('form.requiredFundSource').split(',')[0] + ': '}
                  <b>
                    {street[`requiredFundSource${getLang()}`]}
                  </b>
                </div>}

                {checkVal(street.requiredFundAmount) && <div>
                  {t('form.requiredFundAmount').split(',')[0] + ': '}
                  <b>
                    {!isNaN(street['requiredFundAmount']) && street['requiredFundAmount'] ? (+street['requiredFundAmount']) : street['requiredFundAmount']}
                    {' '}
                    млн {t('tenge')}
                  </b>
                </div>}

                {checkVal(street[`workStart`]) && <div>
                  {t('form.workStart').split(',')[0] + ': '}
                  <b>
                    {formatDate(street['workStart'])}
                  </b>
                </div>}

                {checkVal(street[`workEnd`]) && <div>
                  {t('form.workEnd').split(',')[0] + ': '}
                  <b>
                    {formatDate(street['workEnd'])}
                  </b>
                </div>}

              </>}

          </ul>

          {street.images && Object.keys(street.images).length > 0 && Object.keys(street.images).map((key: any) => (
            <>
              <div>{t(key).split(' ')[0]} <b>{t(key).split(' ')[1]}</b></div>
              <div className="images">
                {street.images[key].map((image: any) =>
                  <>
                    <div className="images__item">
                      <img
                        src={getImageUrl(image.id)}
                        onClick={() => openImageModal({ imageId: image.id, date: formatDate(image.date) }, street.images[key], street[`name${getLang()}`] || t('street-name'))}
                        alt="school"
                        key={`img_${image.id}`}
                      />
                      <div className="footer">
                        <button onClick={() => handleDownload(getImageUrl(image.id))}><DownloadIcon /></button>
                        <div className="images__date">{formatDate(image.date)}</div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </>
          ))}
        </>
      )
      }

    </div >
  )
}

export default Street