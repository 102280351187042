import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';

import { getProjectList } from '../../requests/snp.request';
import { EducationContainer, EducationItem } from './index.styles';

import 'react-toastify/dist/ReactToastify.css';
import { transformList } from './education.component';
import ZoomableImageModal from './zoomable-image.component';
import Documentation from '../reportComponents/documentation.component';
import AdditionalFields from '../reportComponents/additional-fields.component';
import ImageGrid from '../reportComponents/image-grid.component';
import { checkNonEmpty, checkVal, formatDate, getLang } from '../../utils/helpers.utils';

const GasSupply = ({ kato }: { kato: number }) => {
  const { t } = useTranslation();

  const [projectList, setProjectList] = useState<any[]>([]);

  const [selectedImage, setSelectedImage] = useState<number | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [currentImageList, setCurrentImageList] = useState<any[]>([]);
  const [objectName, setObjectName] = useState<string>('Фото');

  const openImageModal = (image: any, images: any, name: string) => {
    setIsOpen(true);
    setSelectedImage(image);
    const imageIdList = images.reduce((acc: any, item: any) => [...acc, ...item.imageIds], []);
    setCurrentImageList(imageIdList);
    setObjectName(name);
  }

  const closeImageModal = () => {
    setIsOpen(false);
    setSelectedImage(null);
    setCurrentImageList([]);
  }

  useEffect(() => {
    const snpData = JSON.parse(localStorage.getItem('snp') as string);
    const val = kato ? kato : snpData.kato
    if (val) {
      getProjectList('gas-supply', val)
        .then((res) => {
          const filtered = res.filter((project: any) => Object.keys(project).some((key) => !['id', 'kato'].includes(key) && checkVal(project[key]) && checkNonEmpty(project[key])));
          const obj = filtered.reduce((acc: any, item: any) => {
            acc[item.id] = res.length <= 3;
            return acc;
          }, {})
          setProjectList(transformList(filtered));
          // setIsVisible(obj);
        })
        .catch((err) => {
          toast.error('Снп не выбрано');
        })
    }
  }, [])

  useEffect(() => {
    getProjectList('gas-supply', kato)
      .then((res) => {
        const filtered = res.filter((project: any) => Object.keys(project).some((key) => !['id', 'kato'].includes(key) && checkVal(project[key]) && checkNonEmpty(project[key])));
          const obj = filtered.reduce((acc: any, item: any) => {
            acc[item.id] = res.length <= 3;
            return acc;
          }, {})
          setProjectList(transformList(filtered));
          // setIsVisible(obj);
      })
      .catch((err) => {
        toast.error('Снп не выбрано');
      })
  }, [kato])

  if (projectList.length === 0) {
    return <p>{t('no-objects')}</p>
  }

  return (
    <>
      <EducationContainer>
        {projectList.length > 0 && projectList.map((project) =>
          <EducationItem key={project.id}>
            <div className="heading">{project[`name${getLang()}`] || `${t('object-name')} ${project.id}`}</div>
            <div className="items">
              {
                (project.constructionYear || project.power) &&
                <div className="item">
                  {
                    project.constructionYear && <div>
                      {t('form.constructionYear')}:
                      <b>{project.constructionYear}</b>
                    </div>
                  }
                  <div className="divider" />

                  {
                    project.power && <div>
                      {t('form.power')}:
                      <b>{project.power}</b>
                      {t('form.people-short')}
                    </div>
                  }
                </div>
              }

              {
                project.factInfo && <div className="item">
                  <div>
                    {t('form.factInfo')}:
                    <b>{project.factInfo}</b>
                  </div>
                </div>
              }
            </div>


            {(project.requirements || project.factCondition) && <div className="block">
              {
                checkVal(project.requirements)
                && <div className="text">
                  {project.requirements !== 'NOT_NEEDED' && `${t('form.requirements')} `}
                  {project.requirements !== 'NOT_NEEDED' ? t(`requirementsOptions.${project.requirements}`).toLowerCase() : t(`requirementsOptions.${project.requirements}`)}
                  {
                    checkVal(project[`requirements${getLang()}`]) && project.requirements !== 'NOT_NEEDED'
                    && <>: {project[`requirements${getLang()}`]}</>
                  }
                </div>
              }

              {
                project.requirements
                && project.requirements !== 'NOT_NEEDED'
                && project.lastMajorRenovation
                && checkVal(project.lastMajorRenovation)
                && <div className="text">{t('requirementsOptions.lastMajorRenovation')}: <b> {formatDate(project.lastMajorRenovation)}</b></div>
              }

              {project.factCondition &&
                <div className="text">{t('form.factCondition')}: <b>{t(project.factCondition.toLowerCase())}</b></div>
              }
            </div>
            }

            {project.requirements && project.requirements !== 'NOT_NEEDED' && <Documentation project={project} />}

            <AdditionalFields project={project} />

                            {project.images && <ImageGrid images={project.images} openImageModal={openImageModal} name={project[`name${getLang()}`] || `${t('object-name')} ${project.id}`} />}

          </EducationItem>
        )}
        <ToastContainer />
        {selectedImage && (
          <ZoomableImageModal
            isFileUpload={true}
            isOpen={isOpen}
            onClose={closeImageModal}
            selectedImage={selectedImage}
            setSelectedImage={setSelectedImage}
            currentImageList={currentImageList}
            objectName={objectName}
          />
        )}
      </EducationContainer>
    </>
  )
}

export default GasSupply