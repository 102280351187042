const eventTypes = [
  "APPROPRIATE_CONDITION",
  "CONSTRUCTION_PURCHASE",
  "RECONSTRUCTION_RESTORATION",
  "CONSTRUCTION_IMPROVEMENT",
  "INSTALLATION_SPORT_GROUNDS",
  "LANDSCAPING",
  "INSTALLATION_FENCES_BARRIERS",
  "DEMOLITION",
  "GARBAGE_REMOVAL",
  "OTHER_INITIATIVES"
]

const fundSourceTypes = [
  "TOO",
  "IP",
  "AO",
  "KH",
  "CXTP",
  "MIO"
]

const realizationTypes = [
  "FACT",
  "PLAN"
]

const dates = [
  'jan',
  'feb',
  'mar',
  'apr',
  'may',
  'jun',
  'jul',
  'aug',
  'sep',
  'oct',
  'nov',
  'dec',
  '2023',
  '2024',
  '2025',
  '2026'
]

export {
  eventTypes,
  fundSourceTypes,
  realizationTypes,
  dates
}