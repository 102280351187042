
import i18n from "../i18n";


const formatDate = (date: string) => {
  if (date) {
    return new Date(date).toLocaleDateString('ru').replaceAll('.', '-') || '';
  } else {
    return '';
  }
}

const t = (key: string, lng: string = 'ru') => i18n.getResource(lng.toLowerCase(), 'translation', key);

const getLang = () => {
  const { language } = i18n;
  return language === 'kz' ? 'Kz' : 'Ru';
}

const capitalize = (str: string) => str?.split(' ').map(str => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()).join(' ').replace(/  +/g, ' ');

const keepCapitalized = (str: string) => str?.split(' ').map(str => str.charAt(0) + str.slice(1).toLowerCase()).join(' ').replace(/  +/g, ' ');

function lowerAndTrim(inputString: string) {
  if (inputString) {
    const words = inputString.split(' ');

    const processWord = (word: string) => {
      let capitalCount = 0;
      let modifiedWord = '';

      for (let i = 0; i < word.length; i++) {
        if (word[i] === word[i].toUpperCase()) {
          capitalCount++;

          if (capitalCount <= 4) {
            modifiedWord += word[i];
          } else {
            modifiedWord += word[i].toLowerCase();
          }
        } else {
          modifiedWord += word[i].toLowerCase();
        }
      }

      return modifiedWord;
    };

    const processedWords = words.map(processWord);

    const result = processedWords.join(' ').replace(/  +/g, ' ');

    return result;
  }

  return '';
}

const formatNumber = (number: string) => number?.toString().split('').filter((item: string) => +item >= 0).join('') || '0'

const formatSalary = (number: string) => {
  const reversed = number?.toString().replaceAll(' ', '')?.split('').reverse().join('');
  const formatted = reversed?.replace(/(\d{3})/g, '$1 ').trim();
  return formatted?.split('').reverse().join('');
}
const checkVal = (val: any) => val !== null && val !== undefined && val !== '' && +val !== 0
const checkValNonZero = (val: any) => val !== null && val !== undefined && val !== ''
const checkNonEmpty = (val: any) => Array.isArray(val) ? val.length > 0 : typeof val === 'object' && Object.keys(val).length > 0

const unique = (arr: any, keyProps: any) => {
  const kvArray = arr.map((entry: any) => {
    const key = keyProps.map((k: any) => entry[k]).join('|');
    return [key, entry];
  });
  const map = new Map(kvArray);
  return Array.from(map.values());
}

const roundNumberArray = (arr: any[]) => {
  if (!arr) return [];

  const res = arr.map((item) => {
    if (Number(item) === item && item % 1 !== 0) {
      return item.toFixed(2);
    } else {
      return item;
    }
  });

  return res;
}


export {
  formatDate,
  t,
  getLang,
  capitalize,
  lowerAndTrim,
  keepCapitalized,
  formatNumber,
  checkVal,
  formatSalary,
  checkValNonZero,
  unique,
  checkNonEmpty,
  roundNumberArray  
}