import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { AgricultureWrapper, EducationContainer, EducationItem } from './index.styles';
import { ArrowDown } from '../../assets/icons';
import { checkVal, getLang } from '../../utils/helpers.utils';
import ImageGrid from '../reportComponents/image-grid.component';
import ZoomableImageModal from './zoomable-image.component';
import { getDistrictData } from '../../requests/district.request';
import { transformList } from './education.component';

type TabType = '2023' | '2024' | 'compare';

const monthNames: any = {
  "январь": "қаңтар",
  "февраль": "ақпан",
  "март": "наурыз",
  "апрель": "сәуір",
  "май": "мамыр",
  "июнь": "маусым",
  "июль": "шілде",
  "август": "тамыз",
  "сентябрь": "қыркүйек",
  "октябрь": "қазан",
  "ноябрь": "қараша",
  "декабрь": "желтоқсан"
}

const DistrictImprovement = () => {
  const { t } = useTranslation();
  const { kato } = useParams();
  const [tab, setTab] = useState<TabType>('2023');
  const [selectedImage, setSelectedImage] = useState<number | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [currentImageList, setCurrentImageList] = useState<any[]>([]);
  const [data, setData] = useState<{ '2023': any, '2024': any }>({ '2023': null, '2024': null });

  const [isAllOpen, setIsAllOpen] = useState<boolean>(false);
  const [isListOpen, setIsListOpen] = useState<{ [key: string]: boolean }>({});
  const [isTypeOpen, setIsTypeOpen] = useState<{ [key: string]: boolean }>({
    APPROPRIATE_CONDITION: false,
    CONSTRUCTION_PURCHASE: false,
    RECONSTRUCTION_RESTORATION: false,
    CONSTRUCTION_IMPROVEMENT: false,
    INSTALLATION_SPORT_GROUNDS: false,
    LANDSCAPING: false,
    INSTALLATION_FENCES_BARRIERS: false,
    DEMOLITION: false,
    GARBAGE_REMOVAL: false,
    OTHER_INITIATIVES: false,
  });

  const itemToTabName: any = {
    '2023': `${t('district-improvement-form.FACT')} [2023]`,
    '2024': `${t('district-improvement-form.PLAN')} [2024]`,
  }

  const getDate = (date: string) => {
    if (t(date)) {
      return t(date);
    } else if (getLang() === 'Kz' && monthNames[date]) {
      return monthNames[date];
    } else {
      return date;
    }
  }

  const handleOpen = (id: any) => {
    setIsListOpen(prev => ({ ...prev, [id]: !prev[id] }));
  }

  const handleOpenAll = () => {
    const obj = Object.keys(isTypeOpen).reduce((acc: any, key: any) => {
      acc[key] = !isAllOpen;
      return acc;
    }, {})

    setIsTypeOpen(obj);
  }

  const openImageModal = (imageId: number, images: any) => {
    setIsOpen(true);
    setSelectedImage(imageId);
    const imageIdList = images.reduce((acc: any, item: any) => [...acc, ...item.imageIds], []);
    setCurrentImageList(imageIdList);
  }

  const closeImageModal = () => {
    setIsOpen(false);
    setSelectedImage(null);
    setCurrentImageList([]);
  }

  const handleSelectTab = (tab: TabType) => {
    setTab(tab);
  }

  const DataList = (year: '2023' | '2024') => <EducationContainer>
    {data[year]
      && Object.keys(data[year]).length > 0
      && Object.keys(data[year]).map((key) =>
        <EducationItem key={key} isType>
          <div className="heading with-icon" onClick={() => setIsTypeOpen(prev => ({ ...prev, [key]: !prev[key] }))}>
            <ArrowDown width="12" style={{ transform: isTypeOpen[key] ? 'rotate(180deg)' : 'rotate(270deg)' }} />
            {t(`eventType.${key}`)}
          </div>


          {isTypeOpen[key] && <>
            {transformList(data[year][key]).map((project: any) => <EducationItem key={project.id}>
              <div className="heading with-icon" onClick={() => handleOpen(project.id)}>
                <ArrowDown width="12" style={{ transform: isListOpen[project.id] ? 'rotate(180deg)' : 'rotate(270deg)' }} />
                {project[`name${getLang()}`] || `${t('object-name')} ${project.id}`}
              </div>

              <div className="body" style={{ padding: isListOpen[project.id] ? '0 0.9375rem 0.9375rem' : '0rem' }}>
                {
                  isListOpen[project.id] && <>

                    {checkVal(project[`snpName${getLang()}`]) && <div> {t(`form.snp.name`)}: <b>{project[`snpName${getLang()}`]}</b> </div>}
                    {checkVal(project['eventType']) && <div> {t(`district-improvement-form.eventType`)}: <b>{t(`eventType.${project['eventType']}`)}</b> </div>}
                    {/* {checkVal(project['realizationType']) && <div> {t(`district-improvement-form.realizationType`)}: <b>{t(`district-improvement-form.${project['realizationType']}`)}</b> </div>} */}
                    {checkVal(project['fundSourceType']) && checkVal(project[`fundSourceName${getLang()}`]) && <div> {t(`district-improvement-form.fundSource`)}: <b> {t(`district-improvement-form.${project['fundSourceType']}`) + ' '} {project[`fundSourceName${getLang()}`]}</b></div>}
                    {checkVal(project['fundSourceAmount']) && <div> {t(`district-improvement-form.fundSourceAmount`)}: <b> {project['fundSourceAmount']}</b></div>}
                    {checkVal(project['workStart']) && checkVal(project['workEnd']) && <div> {t(`district-improvement-form.dates`)}: <b>{getDate(project['workStart'])} - {getDate(project['workEnd'])}</b> </div>}

                                    {project.images && <ImageGrid images={project.images} openImageModal={openImageModal} name={project[`name${getLang()}`] || `${t('object-name')} ${project.id}`} />}

                  </>
                }
              </div>

            </EducationItem>
            )}
          </>}
        </EducationItem>)}
  </EducationContainer>

  useEffect(() => {
    setIsAllOpen(Object.values(isTypeOpen).every((value: any) => value === true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Object.values(isTypeOpen)])

  useEffect(() => {
    const snpData = JSON.parse(localStorage.getItem('snp') as string);
    const val = kato ? kato : snpData.kato
    if (val && tab !== 'compare') {
      getDistrictData(+val, +tab)
        .then((res) => {
          setData((prev: any) => ({ ...prev, [tab]: res }))
        })
        .catch((err) => {
          toast.error('Снп не выбрано');
        })
    }
  }, [])

  useEffect(() => {
    if (kato) {
      if (tab === 'compare') {
        ['2023', '2024'].forEach((item) => {
          getDistrictData(+kato, +item)
            .then((res) => {
              setData((prev: any) => ({ ...prev, [item]: res }))
            })
            .catch((err) => {
              toast.error('Снп не выбрано');
            })
        })
      } else {
        getDistrictData(+kato, +tab)
          .then((res) => {
            setData((prev: any) => ({ ...prev, [tab]: res }))
          })
          .catch((err) => {
            toast.error('Снп не выбрано');
          })
      }
    }
  }, [kato, tab])

  return (
    <AgricultureWrapper>
      <div className="tabs">
        {['2023', '2024'].map((item: string) => (
          <div key={item} className={`tab ${item === tab ? 'active' : ''}`} onClick={() => handleSelectTab(item as TabType)}>{item}</div>
        ))}
      </div>

      {tab === 'compare' && <>
        <div className='list-heading with-icon' onClick={handleOpenAll}>
          {t('list')}
          <ArrowDown width="12" style={{ transform: isAllOpen ? 'rotate(180deg)' : 'rotate(270deg)' }} />
        </div>
        <div className="compare-columns">
          {['2023', '2024'].map((item: string) => (
            <div className='column'>
              {DataList(item as '2023' | '2024')}
            </div>
          ))}
        </div>
      </>}

      {
        ['2023', '2024'].includes(tab) && <>
          <div className='list-heading with-icon' onClick={handleOpenAll}>
            {t('list')}
            <ArrowDown width="12" style={{ transform: isAllOpen ? 'rotate(180deg)' : 'rotate(270deg)' }} />
          </div>

          {DataList(tab as '2023' | '2024')}
        </>
      }

      {
        selectedImage && (
          <ZoomableImageModal
            isFileUpload={true}
            isOpen={isOpen}
            onClose={closeImageModal}
            selectedImage={selectedImage}
            setSelectedImage={setSelectedImage}
            currentImageList={currentImageList}
          />
        )
      }
      <ToastContainer />

    </AgricultureWrapper >

  )
}

export default DistrictImprovement