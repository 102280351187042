import { DiagramType } from "../interfaces/snp.interface"

const karagandaDistricts: {
  kato: number,
  coordinates: [number, number],
}[] = [
    { kato: 353200000, coordinates: [72.85, 49.62] }, // Абай
    { kato: 353600000, coordinates: [74.87314542447777, 48.201354002705166], }, // Актогай
    { kato: 354000000, coordinates: [73.84475673548927, 50.082601287378324], }, // Бухар-Жырау
    { kato: 354800000, coordinates: [75.45028707012514, 49.409129720476486], }, // Каркаралинский
    { kato: 355200000, coordinates: [69.72827629268257, 49.66942469691893], }, // Нуринский
    { kato: 355600000, coordinates: [73.4066783636805, 50.73425269204605], }, // Осакаровский
    { kato: 356400000, coordinates: [73.46103449434024, 48.306662785233485], }, // Шетский
  ]

const diagramTypes: DiagramType[] = [
  'population',
  // 'nationality',
  'employment',
  'education',
  'healthcare',
  'studying',
  'road',
  'harvest',
  'lifestock',
  'finance',
]

const options = [
  { label: 'GOOD', value: 'GOOD' },
  { label: 'SATISFACTORY', value: 'SATISFACTORY' },
  { label: 'BAD', value: 'BAD' },
]

const roadCoverOptions = [
  { label: 'PAVED', value: 'PAVED' },
  { label: 'GROUND', value: 'GROUND' },
  { label: 'GRAVEL', value: 'GRAVEL' },
]

const documentationStatusOptions = [
  { label: 'HAVE', value: 'HAVE' },
  { label: 'IN_PROCESS', value: 'IN_PROCESS' },
  { label: 'NOT_HAVE', value: 'NOT_HAVE' },
]

const educationOptions = [
  { label: 'school', value: 'true' },
  { label: 'kindergarten', value: 'false' },
]

const requirementsOptions = [
  'NOT_NEEDED',
  'MAJOR_RENOVATION',
  'CURRENT_RENOVATION',
  'RECONSTRUCTION'
]

const sportSectionsList: string[] = [
  "no",
  "basketball",
  "boxing",
  "volleyball",
  "freestyle-wrestling",
  "greco-roman-wrestling",
  "judo",
  "kazakh-wrestling",
  "karate",
  "athletics",
  "skiing",
  "ping-pong",
  "swimming",
  "taekwondo",
  "dancing",
  "9kumalak",
  "weight-lifting",
  "figure-skating",
  "football",
  "hockey",
  "gymnastics",
  "chess"
];

const sportSectionOptions = sportSectionsList.map(section => ({ label: section, value: section }));


export {
  karagandaDistricts,
  diagramTypes,
  options,
  roadCoverOptions,
  documentationStatusOptions,
  educationOptions,
  requirementsOptions,
  sportSectionsList,
  sportSectionOptions
}