import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { Formik, Field, Form } from 'formik';
import { ToastContainer, toast } from 'react-toastify';

import { updateInfrastructureStreet, createInfrastructureStreet, createObject, updateObject, deleteInfrastructureStreet } from '../../requests/supervisor.request';
import { getProject } from '../../requests/snp.request';

import { AddField } from '../../assets/icons';

import { Wrapper, FormGroup } from '../admin-page/admin-page.styles';
import { FormBlock, FormBlockButton } from './infrastructure.styles';

import { IProject } from '../../interfaces/snp.interface';

import 'react-toastify/dist/ReactToastify.css';
import ImageGrid from './image-grid.component';
import AdditionalFieldsForm from '../formComponents/additional-fields-form.component';
import { options } from '../../constants/snp.constant';
import { checkVal, lowerAndTrim, t } from '../../utils/helpers.utils';
import FieldsComponent from './fields.components';
import FormFooter from './footer.component';
import Tabs from './tabs.component';
import { ITempFund, ITempVal, documentationKeys, errMsg } from './education-form.component';
import StreetDocumentationForm from '../formComponents/street-documentation-form.component';


const initialFormData = {
  "id": 0,
  "kato": 0,
  "totalStreetNumber": 0,
  "totalStreetLength": 0,
  "lightenedStreetNumber": 0,
  "lightenedStreetLength": 0,
  "lighteningCover": 0,
  "streetList": [],
}

const defaultStreet = {
  nameRu: '',
  nameKz: '',
  totalStreetLength: '',
  repairRequired: '',
  documentationStatus: '',
  requiredFundSourceRu: '',
  requiredFundSourceKz: '',
  requiredFundAmount: '',
  workStart: '',
  workEnd: '',
  files: [],
  images: [],
  id: 0,
  factCondition: '',
}

const skipList = [
  "nameEn",
  "additionalFields",
  "streetList",
  "lighteningCover",
  "lightenedStreetNumber",
  "factCondition",
]

export const streetSkipList = [
  ...documentationKeys,
  "nameEn",
  "requiredFund",
  "files",
]

export const defaultBody = {
  labelKz: '',
  labelRu: '',
  value: '',
  isAdding: false,
  labelError: false,
  valueError: false
}

const VillageRoadForm = () => {
  const { i18n: { language } } = useTranslation();
  const { objectId, kato } = useParams<any>();
  const navigate = useNavigate();
  const wrapperRef = useRef<HTMLDivElement>(null);

  const [formData, setFormData] = useState<any>(initialFormData);
  const [isAddingStreet, setIsAddingStreet] = useState(false);
  const [newStreet, setNewStreet] = useState<any>(defaultStreet);
  const [errors, setErrors] = useState<any>({});
  const [tempVal, setTempVal] = useState<ITempVal>({
    isAdding: false,
    labelKz: '',
    labelRu: '',
    valueKz: '',
    valueRu: '',
    isAddingText: false,
    textKz: '',
    textRu: '',
    labelError: false,
    valueError: false,
    textError: false
  });
  const [tempFund, setTempFund] = useState<{ [key: string]: ITempFund }>({})

  const addNewStreet = () => {
    setErrors({});

    const keys = Object.keys(newStreet).filter(key => !streetSkipList.includes(key));

    for (const key of keys) {
      if (!checkVal(newStreet[key]) && typeof newStreet[key] !== 'boolean') {
        setErrors((prev: any) => ({ ...prev, [`${errMsg[key]}-street`]: true }))
        toast.error(t(`errors.${errMsg[key]}`, language))
        return false;
      }
    }

    if (newStreet.repairRequired === 'true' || newStreet.repairRequired === true) {
      if (newStreet.documentationStatus === 'HAVE') {
        if (!checkVal(newStreet.documentationStatus)) {
          setErrors({ ...errors, [`documentationStatus-new`]: true })
          toast.error(t(`errors.documentationStatus`, language))
          return
        } else if (newStreet.documentationStatus === 'HAVE') {
          for (const key of documentationKeys.filter(item => !['requiredFund', 'documentationStatus'].includes(item))) {
            if (!checkVal(newStreet[key])) {
              setErrors((prev: any) => ({ ...prev, [`${errMsg[key]}-new`]: true }))
              toast.error(t(`errors.${errMsg[key]}`, language))
              return
            }
          }
        }
      }
    }

    let field = newStreet.requiredFund;

    if (tempFund['new'].isAdding) {
      if (!checkVal(tempFund['new'].labelKz) || !checkVal(tempFund['new'].labelRu)) {
        setTempFund((prev: any) => ({ ...prev, new: { ...prev.new, labelError: true } }))
        toast.error(t(`errors.additional-field`, language))
        return false;
      }

      if (!checkVal(tempFund['new'].value)) {
        setTempFund((prev: any) => ({ ...prev, new: { ...prev.new, valueError: true } }))
        toast.error(t(`errors.additional-value`, language))
        return false;
      }

      const { labelKz, labelRu, value } = tempFund['new'];
      field = {
        ...newStreet.requiredFund, [+new Date()]: { labelKz, labelRu, value }
      }
    }

    const updatedStreets = [
      ...formData.streetList,
      {
        ...newStreet,
        totalStreetLength: +newStreet.totalStreetLength,
        repairRequired: newStreet.repairRequired === 'true' ? true : false,
        requiredFund: field,
      },
    ];

    const data = {
      ...formData,
      streetList: updatedStreets,
    }

    setFormData((prev: any) => data);
    updateInfrastructureStreet('VILLAGE_ROAD_STREET', updatedStreets);
    setNewStreet(defaultStreet)
    setIsAddingStreet(false)

    return true
  }

  const handleSave = (values: any) => {
    setErrors({})
    setTempVal({ ...tempVal, textError: false, labelError: false, valueError: false })

    let streetList = formData.streetList;

    const fund_keys = Object.keys(tempFund).filter((key: string) => !['new', 'ru', 'kz'].includes(key));

    if (fund_keys) {
      for (const key of fund_keys) {
        if (tempFund[key].isAdding) {
          if (!checkVal(tempFund[key].labelKz) || !checkVal(tempFund[key].labelRu)) {
            setTempFund((prev: any) => ({ ...prev, [key]: { ...prev[key], labelError: true } }))
            toast.error(t(`errors.additional-field`, language))
            return
          }

          if (!checkVal(tempFund[key].value)) {
            setTempFund((prev: any) => ({ ...prev, [key]: { ...prev[key], valueError: true } }))
            toast.error(t(`errors.additional-value`, language))
            return
          }

          streetList = streetList.map((item: any) => +item.id === +key ? { ...item, requiredFund: addFund(key) } : item)
        }
      }
    }

    updateInfrastructureStreet('VILLAGE_ROAD_STREET', streetList).then((res: any) => {
      let data = {
        ...values,
        additionalFields: formData.additionalFields,
        streetList: res.list,
      }

      delete data.streets

      documentationKeys.forEach(key => delete data[key])

      const keys = Object.keys(data).filter((key: string) => !skipList.includes(key));

      for (const key of keys) {
        if (!checkVal(data[key]) && typeof data[key] !== 'boolean') {
          if (!(key === 'lightenedStreetLength' && data[key] === '0')) {
            setErrors((prev: any) => ({ ...prev, [errMsg[key]]: true }))
            toast.error(t(`errors.${errMsg[key]}`, language))
            return
          }
        }
      }

      if (data.streetList.length !== 0) {
        const list = data.streetList;
        for (const street of list) {
          const keys = Object.keys(street).filter((key: string) => !streetSkipList.includes(key));

          for (const key of keys) {
            if (!checkVal(street[key]) && typeof street[key] !== 'boolean') {
              setErrors((prev: any) => ({ ...prev, [`${errMsg[key]}-${street.id}`]: true }))
              toast.error(t(`errors.${errMsg[key]}`, language))
              return
            }
          }

          if (street.repairRequired === 'true' || street.repairRequired === true) {
            if (!checkVal(street.documentationStatus)) {
              setErrors({ ...errors, [`documentationStatus-${street.id}`]: true })
              toast.error(t(`errors.documentationStatus`, language))
              return
            } else if (data.documentationStatus === 'HAVE') {
              for (const key of documentationKeys.filter(item => !['requiredFund', 'documentationStatus'].includes(item))) {
                if (!checkVal(street[key])) {
                  setErrors((prev: any) => ({ ...prev, [`${errMsg[key]}-${street.id}`]: true }))
                  toast.error(t(`errors.${errMsg[key]}`, language))
                  return
                }
              }
            }
          }

          if (street.files.length === 0) {
            setErrors((prev: any) => ({ ...prev, [`files-${street.id}`]: true }))
            toast.error(t(`errors.files`, language))
            return
          }
        }
      }

      if (tempVal.isAddingText) {
        if (!checkVal(tempVal.textRu) || !checkVal(tempVal.textKz)) {
          setTempVal({ ...tempVal, textError: true })
          toast.error(t(`errors.additional-value`, language))
          return
        }
        data = {
          ...data,
          additionalFields: addText()
        }
      }

      if (tempVal.isAdding) {
        if (!checkVal(tempVal.labelKz) || !checkVal(tempVal.labelRu)) {
          setTempVal({ ...tempVal, labelError: true })
          toast.error(t(`errors.additional-field`, language))
          return
        }

        if (!checkVal(tempVal.valueKz) || !checkVal(tempVal.valueRu)) {
          setTempVal({ ...tempVal, valueError: true })
          toast.error(t(`errors.additional-value`, language))
          return
        }

        data = {
          ...data,
          additionalFields: addField()
        }
      }

      if (isAddingStreet) {
        if (addNewStreet()) {
          updateObject('village-road', data)
            .then(() => {
              toast.success(t(`toast.save_success`, language))
              loadForm()
            })
            .catch(() => toast.error(t(`toast.save_error`, language)))
        }
      } else {
        updateObject('village-road', data)
          .then(() => {
            toast.success(t(`toast.save_success`, language))
            loadForm()
          })
          .catch(() => toast.error(t(`toast.save_error`, language)))
      }
    })
  }

  const getUpdatedFormData = (objectId: number) => {
    getProject('village-road', objectId).then(res => {
      setFormData(res.infrastructureEntity);
      setTempFund(res.infrastructureEntity.streetList.reduce((acc: any, item: any) => {
        acc[item.id] = { ...defaultBody }
        return acc
      }, {
        new: { ...defaultBody }
      }))
    })
  }

  const handleInputChange = (valueKey: string, value: string, streetId: number) => {
    setFormData({
      ...formData, streetList: formData.streetList.map((item: any) => item.id === streetId ? {
        ...item,
        [valueKey]: value,
      }
        : item
      )
    })
  }

  const loadForm = useCallback(() => {
    const snpInfo = JSON.parse(localStorage.getItem('snp') as string)
    const val = kato ? kato : snpInfo.kato;
    if (objectId && objectId !== 'new') {
      getUpdatedFormData(+objectId)
    } else {
      createObject('village-road', val).then(res => {
        navigate(`/admin/${val}/infrastructure/village-road/${res.id}`);
      })
    }
  }, [navigate, objectId, kato])

  const createStreet = () => {
    createInfrastructureStreet('VILLAGE_ROAD_STREET', formData.id).then(res => {
      setNewStreet(res);
      setIsAddingStreet(true);
    })
  }

  const formatStreetImages = (inputArray: any) => {
    const formattedObject = inputArray.reduce((result: any, item: any) => {
      if (!result[item.fileType]) {
        result[item.fileType] = [];
      }
      result[item.fileType].push(item);
      return result;
    }, {});

    return formattedObject
  }

  const handleDeleteStreet = (id: number) => {
    deleteInfrastructureStreet('VILLAGE_ROAD_STREET', id).then(res => {
      getUpdatedFormData(+objectId!)
    })
  }

  const addField = () => {
    if (!checkVal(tempVal.labelKz) || !checkVal(tempVal.labelRu)) {
      setTempVal((prev: any) => ({ ...prev, labelError: true }))
      toast.error(t(`errors.additional-field`, language))
      return
    }

    if (!checkVal(tempVal.valueKz) || !checkVal(tempVal.valueRu)) {
      setTempVal((prev: any) => ({ ...prev, valueError: true }))
      toast.error(t(`errors.additional-value`, language))
      return
    }

    const field = {
      ...formData.additionalFields,
      [+new Date()]: {
        labelKz: tempVal.labelKz,
        labelRu: tempVal.labelRu,
        valueKz: tempVal.valueKz,
        valueRu: tempVal.valueRu,
      }
    }

    setFormData({
      ...formData,
      additionalFields: field
    })
    setTempVal({ ...tempVal, labelKz: '', valueKz: '', isAdding: false, labelRu: '', valueRu: '', labelError: false, valueError: false })
    return field
  }

  const addText = () => {
    if (!checkVal(tempVal.textRu) || !checkVal(tempVal.textKz)) {
      setTempVal((prev: any) => ({ ...prev, textError: true }))
      toast.error(t(`errors.additional-value`, language))
      return
    }

    const field = {
      ...formData.additionalFields,
      [`no-label-${+new Date()}`]: { textKz: tempVal.textKz, textRu: tempVal.textRu }
    }

    setFormData({
      ...formData,
      additionalFields: field
    })

    setTempVal({ ...tempVal, textRu: '', textKz: '', isAddingText: false, textError: false })
    return field
  }

  const deleteField = (key: string) => {
    const { [key]: deleted, ...rest } = formData.additionalFields;
    setFormData({ ...formData, additionalFields: rest })
    setTempVal({ ...tempVal, isAdding: false, labelError: false, valueError: false })
    updateObject('VILLAGE_ROAD', { ...formData, additionalFields: rest })
      .then(() => loadForm())
  }

  const addFund = (streetId: number | string) => {
    if (!checkVal(tempFund[streetId]?.labelKz) || !checkVal(tempFund[streetId]?.labelRu)) {
      setTempFund((prev: any) => ({ ...prev, [streetId]: { ...prev[streetId], labelError: true } }))
      toast.error(t(`errors.additional-field`, language))
      return
    }

    if (!checkVal(tempFund[streetId].value)) {
      setTempFund((prev: any) => ({ ...prev, [streetId]: { ...prev[streetId], valueError: true } }))
      toast.error(t(`errors.additional-value`, language))
      return
    }

    const { labelKz, labelRu, value } = tempFund[streetId];
    const street = streetId === 'new' ? newStreet : formData.streetList.find((item: any) => +item.id === +streetId);


    const field = {
      ...street.requiredFund, [+new Date()]: { labelKz, labelRu, value }
    }

    const body = {
      ...formData,
      streetList: formData.streetList.map((item: any) => item.id === streetId ? { ...item, requiredFund: field } : item),
    }

    streetId === 'new' ? setNewStreet({ ...street, requiredFund: field }) : setFormData(body)
    setTempFund((prev: any) => ({ ...prev, [streetId]: { ...defaultBody, labelError: false, valueError: false } }))

    return field;
  }

  const deleteFund = (key: string, streetId: number) => {
    const { [key]: deleted, ...rest } = formData.requiredFund;

    const body = {
      ...formData,
      requiredFund: rest
    }

    setFormData(body)
    updateObject('VILLAGE_ROAD', body).then(() => {
      loadForm()
    })
  }

  const renderFields = (lang: 'Ru' | 'Kz' = 'Ru', setFieldValue: (fieldName: string, value: string) => void) => {
    return <>
      <FormBlock>
        <FormGroup>
          <label className='required' htmlFor={`totalStreetNumber`}>{t(`form.totalStreetNumber`, lang)}</label>
          <Field
            id={`totalStreetNumber`}
            name={`totalStreetNumber`}
            placeholder={t(`form.totalStreetNumber`, lang)}
            as="input"
            type="number"
            className={errors['totalStreetNumber'] ? 'error' : ''}
            min={0}
          />
        </FormGroup>
        <FormGroup>
          <label className='required' htmlFor={`totalStreetLength`}>{t(`form.totalStreetLength`, lang)}</label>
          <Field
            id={`totalStreetLength`}
            name={`totalStreetLength`}
            placeholder={t(`form.totalStreetLength`, lang)}
            as="input"
            type="number"
            min={0}
            step={'0.001'}
            value={formData.totalStreetLength || 0}
            onChange={(e: any) => {
              const lightenedVal = formData.lightenedStreetLength ? Math.min(+e.target.value, +formData.lightenedStreetLength) : 0;
              const sum = ((+lightenedVal / +e.target.value) * 100).toFixed(2);
              setFieldValue('totalStreetLength', e.target.value)
              setFieldValue('lighteningCover', sum.toString())
              setFieldValue('lightenedStreetLength', lightenedVal.toString())
              setFormData({ ...formData, totalStreetLength: +e.target.value, lighteningCover: sum, lightenedStreetLength: lightenedVal })
            }}
            className={errors['totalStreetLength'] ? 'error' : ''}
          />
        </FormGroup>

        <FormGroup>
          <label className='required' htmlFor={`lightenedStreetLength`}>{t(`form.asphaltedStreetLength`, lang)}</label>
          <Field
            id={`lightenedStreetLength`}
            name={`lightenedStreetLength`}
            placeholder={t(`form.asphaltedStreetLength`, lang)}
            as="input"
            type="number"
            min={0}
            step={'0.001'}
            value={formData.lightenedStreetLength || 0}
            max={+formData.totalStreetLength}
            onChange={(e: any) => {
              const val = formData.totalStreetLength ? Math.min(+e.target.value, +formData.totalStreetLength) : +e.target.value;
              const sum = ((val / +formData.totalStreetLength) * 100).toFixed(2);
              setFieldValue('lightenedStreetLength', val.toString())
              setFieldValue('lighteningCover', sum.toString())
              setFormData({ ...formData, lightenedStreetLength: val, lighteningCover: sum })
            }}
            className={errors['lightenedStreetLength'] ? 'error' : ''}
          />
        </FormGroup>
        <FormGroup>
          <label htmlFor={`lighteningCover`}>{t(`form.asphaltCover`, lang)}</label>
          <Field
            id={`lighteningCover`}
            name={`lighteningCover`}
            placeholder={t(`form.asphaltCover`, lang)}
            as="input"
            type="number"
            max={100}
            value={formData.lighteningCover}
            disabled
          />
        </FormGroup>
      </FormBlock>

      {formData.streetList && formData.streetList.length > 0 && formData.streetList.map((item: any) =>
        <FormBlock key={item.id} isStreet>
          <div className='delete-street' onClick={() => handleDeleteStreet(item.id)}>x</div>
          <FormGroup>
            <label className='required' htmlFor={`streetList[${item.id}].streetName`}>{t(`form.streetName`, lang)}</label>
            <input
              id={`streets.${item.id}.streetName`}
              placeholder={t(`form.streetName`, lang)}
              value={item[`name${lang}`]}
              onChange={(e) => handleInputChange(`name${lang}`, lowerAndTrim(e.target.value), item.id)}
              className={errors[`objectName-${item.id}`] ? 'error' : ''}
            />
          </FormGroup>
          <FormGroup>
            <label htmlFor={`streets.${item.id}.roadLength`}>{t(`form.roadLength`, lang)}</label>
            <input
              id={`streets.${item.id}.roadLength`}
              name={`streets.${item.id}.roadLength`}
              placeholder={t(`form.roadLength`, lang)}
              value={item.totalStreetLength}
              onChange={(e) => handleInputChange('totalStreetLength', lowerAndTrim(e.target.value), item.id)}
              min={0}
              type="number"
              step={'0.001'}
              className={errors[`roadLength-${item.id}`] ? 'error' : ''}
            />
          </FormGroup>
          <FormGroup>
            <label htmlFor={`streets.${item.id}.factCondition`}>{t(`form.factCondition`, lang)}</label>
            <select
              id={`streets.${item.id}.factCondition`}
              name={`streets.${item.id}.factCondition`}
              onChange={(e) => handleInputChange('factCondition', e.target.value, item.id)}
              value={item.factCondition}
              placeholder={t(`form.factCondition`, lang)}
              className={errors[`factCondition-${item.id}`] ? 'error' : ''}
            >
              <option value="" selected hidden></option>
              {
                options.map((option) => (
                  <option key={option.value} value={option.value}>{t(option.label.toLowerCase(), lang)}</option>
                ))
              }
            </select>
          </FormGroup>
          <FormGroup>
            <label htmlFor={`streets.${item.id}.needsRepair`}>{t(`form.repairRequired`, lang)}</label>
            <select
              id={`streets.${item.id}.needsRepair`}
              name={`streets.${item.id}.needsRepair`}
              placeholder={t(`form.repairRequired`, lang)}
              value={item.repairRequired}
              onChange={(e) => handleInputChange('repairRequired', e.target.value, item.id)}
              defaultValue={""}
              className={errors[`repairRequired-${item.id}`] ? 'error' : ''}
            >
              <option value="" hidden></option>
              <option value='true'>{t('true', lang)}</option>
              <option value="false">{t('false', lang)}</option>
            </select>
          </FormGroup>

          {(item.repairRequired === 'true' || item.repairRequired === true)
            && <StreetDocumentationForm
              formData={formData}
              setFormData={setFormData}
              lang={lang}
              setFieldValue={setFieldValue}
              errors={Object.keys(errors).reduce((acc: any, key: string) => { acc[key.split('-')[0]] = errors[key]; return acc }, {})}
              onAdd={addFund}
              tempFund={tempFund}
              setTempFund={setTempFund}
              onDelete={deleteFund}
              streetId={item.id}
            />}

          <ImageGrid
            formData={{ kato: formData.kato, id: item.id } as IProject}
            loadForm={loadForm}
            type={'village_road_street'} images={formatStreetImages(item.files)}
            lang={lang.toLowerCase()}
          />

        </FormBlock >
      )}

      <FormBlock>
        {isAddingStreet && <div className="row sb">
          <FormBlock>
            <FormGroup>
              <label className='required' htmlFor={`streetName`}>{t(`form.streetName`, lang)}</label>
              <input type="text"
                value={newStreet[`name${lang}` as keyof typeof newStreet]}
                onChange={(e) => setNewStreet({ ...newStreet, [`name${lang}`]: lowerAndTrim(e.target.value) })}
                className={errors['objectName-street'] ? 'error' : ''}
              />
            </FormGroup>
            <FormGroup>
              <label className='required' htmlFor={'roadLength'}>{t(`form.roadLength`, lang)}</label>
              <input type="number"
                value={newStreet.totalStreetLength}
                onChange={(e) => setNewStreet({ ...newStreet, totalStreetLength: lowerAndTrim(e.target.value) })}
                min={0}
                step={'0.001'}
                className={errors['totalStreetLength-street'] ? 'error' : ''}
              />

            </FormGroup>
            <FormGroup>
              <label className='required' htmlFor={`factCondition`}>{t(`form.factCondition`, lang)}</label>
              <select
                id={`factCondition`}
                name={`factCondition`}
                placeholder={t(`form.factCondition`, lang)}
                onChange={(e) => setNewStreet({ ...newStreet, factCondition: e.target.value })}
                defaultValue={""}
                className={errors['factCondition-street'] ? 'error' : ''}
                value={newStreet.factCondition}
              >
                <option value="" hidden selected></option>
                {options.map((option) => (
                  <option key={option.value} value={option.value}>
                    {t(option.label.toLowerCase(), lang)}
                  </option>
                ))}
              </select>
            </FormGroup>
            <FormGroup>
              <label className='required' htmlFor={`repairRequired`}>{t(`form.repairRequired`, lang)}</label>
              <select
                id={`repairRequired`}
                name={`repairRequired`}
                placeholder={t(`form.repairRequired`, lang)}
                onChange={(e) => setNewStreet({ ...newStreet, repairRequired: e.target.value })}
                defaultValue={""}
                value={newStreet.repairRequired}
              >
                <option value="" hidden></option>
                <option value="true">{t('true', lang)}</option>
                <option value="false">{t('false', lang)}</option>
              </select>
            </FormGroup>

            {(newStreet.repairRequired === 'true' || newStreet.repairRequired === true)
              && <StreetDocumentationForm
                formData={formData}
                setFormData={setFormData}
                lang={lang}
                setFieldValue={setFieldValue}
                errors={Object.keys(errors).reduce((acc: any, key: string) => { acc[key.split('-')[0]] = errors[key]; return acc }, {})}
                onAdd={addFund}
                tempFund={tempFund}
                setTempFund={setTempFund}
                onDelete={deleteFund}
                streetId={'new'}
                setNewStreet={setNewStreet}
                newStreet={newStreet}
              />}
          </FormBlock>
        </div>}

        {isAddingStreet
          ? <div style={{ display: 'flex', gap: '0.625rem' }}>
            <FormBlockButton onClick={addNewStreet}>{t('save', lang)}</FormBlockButton>
            <FormBlockButton onClick={() => setIsAddingStreet(false)}>{t('cancel', lang)}</FormBlockButton>
          </div>
          : <FormBlockButton onClick={createStreet}>
            <AddField /> {t('add-street', lang)}
          </FormBlockButton>
        }
      </FormBlock>

      <AdditionalFieldsForm
        formData={formData}
        setFormData={setFormData}
        lang={lang}
        setFieldValue={setFieldValue}
        onSave={handleSave}
        setTempVal={setTempVal}
        errors={errors}
        tempVal={tempVal}
        onAdd={addField}
        onTextAdd={addText}
        onDelete={deleteField}
        type='village-road'
      />
    </>
  }

  useEffect(() => {
    loadForm();
  }, [loadForm, objectId])

  return (
    <Wrapper ref={wrapperRef}>
      <Tabs />
      {
        formData.id > 0 && <>
          <Formik
            initialValues={formData}
            onSubmit={(values) => handleSave(values)}
          >
            {({ values, setFieldValue }) => (
              <Form >
                <FieldsComponent renderFields={renderFields} setFieldValue={setFieldValue} />
                <FormFooter type='village-road' formId={formData.id}parent={wrapperRef?.current} />
              </Form>
            )}
          </Formik>
        </>
      }
      <ToastContainer />
    </Wrapper >
  )
}

export default VillageRoadForm