import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import SearchIcon from '../../assets/icons/search.svg'
import XIcon from '../../assets/icons/x.svg'

import { InputIcons, OptionsList, SearchInputWrapper } from './index.styles'
import { ISnp } from '../../interfaces/snp.interface';
import { getLang } from '../../utils/helpers.utils';

interface IProps {
  searchValue: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClear: () => void;
  onSearch: () => void;
  options: { label: string, value: ISnp }[];
  onSelectOption: (option: { label: string, value: ISnp }) => void;
}

const Search: React.FC<IProps> = ({ searchValue, onChange, onClear, onSearch, options, onSelectOption }) => {
  const { t } = useTranslation();
  const listRef = useRef<HTMLInputElement | null>(null);

  const [showOptions, setShowOptions] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState<any[]>([]);
  const [isImportant, setIsImportant] = useState(false);

  const handleOptionClick = (option: { label: string, value: ISnp }) => {
    if (option.value.fillingStatus === 'NOT_STARTED') {
      return;
    }
    onSelectOption(option);
    setShowOptions(false);
  };

  const handleClear = () => {
    onClear();
    setShowOptions(false);
  }

  useEffect(() => {
    options && options.length > 0 && setFilteredOptions(options.sort(item => item.value.fillingStatus === 'COMPLETED' || item.value.fillingStatus === 'IN_PROCESS' ? -1 : 1));
  }, [options]);

  useEffect(() => {
    const filtered: { label: string, value: ISnp }[] = isImportant
      ? options
        .filter((option) => option.label.toLowerCase()?.includes(searchValue.toLowerCase()) && isImportant === option.value.isSupport)
        .sort(item => item.value.fillingStatus === 'COMPLETED' || item.value.fillingStatus === 'IN_PROCESS' ? -1 : 1)
      : options
        .filter((option) => option.label.toLowerCase()?.includes(searchValue.toLowerCase()))
        .sort(item => item.value.fillingStatus === 'COMPLETED' || item.value.fillingStatus === 'IN_PROCESS' ? -1 : 1)


    setFilteredOptions(filtered);

  }, [options, searchValue, isImportant]);


  useEffect(() => {
    searchValue.length > 0 && setShowOptions(true);
  }, [searchValue])

  useEffect(() => {
    const handleOutsideClick = (e: any) => {
      if (listRef.current && !listRef.current.contains(e.target)) {
        setShowOptions(false);
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  return (
    <SearchInputWrapper ref={listRef}>
      <input
        type="text"
        placeholder={t('chooseSnp')}
        onChange={onChange}
        value={searchValue}
        onFocus={() => setShowOptions(true)}
      />
      <InputIcons>
        <img src={SearchIcon} alt="search icon" onClick={onSearch} />
        <span className="divider"></span>
        <img src={XIcon} alt="clear icon" onClick={handleClear} />
      </InputIcons>
      {showOptions && filteredOptions && (
        <>
          <input type="checkbox" checked={isImportant} onChange={() => setIsImportant(!isImportant)} /> {t('important-snp')}
          <OptionsList>
            {filteredOptions.map((option, index) => (
              <div className={`item ${option.value.fillingStatus !== 'NOT_STARTED' ? '' : 'disabled'}`} key={index} onClick={() => handleOptionClick(option)}>
                {option.label}  <span className="add-label">{" " + option.value[`pname${getLang()}`]}, {option.value[`ppName${getLang()}`]}</span>
              </div>
            ))}
          </OptionsList>
        </>

      )}
    </SearchInputWrapper>
  );
};

export default Search