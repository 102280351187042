import React, { Fragment, useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { getProjectList } from '../../requests/snp.request';
import { AgricultureWrapper, EducationContainer, EducationItem } from './index.styles';
import { transformList } from './education.component';
import ZoomableImageModal from './zoomable-image.component';
import AdditionalFields from '../reportComponents/additional-fields.component';
import ImageGrid from '../reportComponents/image-grid.component';
import { capitalize, formatNumber, formatSalary, getLang } from '../../utils/helpers.utils';
import { ArrowDown } from '../../assets/icons';

const AgricultureProducers = ({ kato }: { kato: number }) => {

  const { t, i18n: { language } } = useTranslation();
  const [isAllOpen, setIsAllOpen] = useState<boolean>(false);
  const [isListOpen, setIsListOpen] = useState<{ [key: string]: boolean }>({});

  const [projectList, setProjectList] = useState<any[]>([]);
  const [selectedImage, setSelectedImage] = useState<number | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [currentImageList, setCurrentImageList] = useState<any[]>([]);
  const [objectName, setObjectName] = useState<string>('Фото');

  const handleOpen = (id: any) => {
    setIsListOpen(prev => ({ ...prev, [id]: !prev[id] }));
  }

  const handleOpenAll = () => {
    const obj = Object.keys(isListOpen).reduce((acc: any, key: any) => {
      acc[key] = !isAllOpen;
      return acc;
    }, {})

    setIsListOpen(obj);
  }

  useEffect(() => {
    const obj = projectList.reduce((acc: any, street: any) => {
      acc[street.id] = projectList.length < 3;
      return acc;
    }, {})
    setIsListOpen(obj);

    setIsAllOpen(Object.values(obj).every((value: any) => value === true));
  }, [projectList])

  useEffect(() => {
    setIsAllOpen(Object.values(isListOpen).every((value: any) => value === true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Object.values(isListOpen)])



  const openImageModal = (image: any, images: any, name: string) => {
    setIsOpen(true);
    setSelectedImage(image);
    const imageIdList = images.reduce((acc: any, item: any) => [...acc, ...item.imageIds], []);
    setCurrentImageList(imageIdList);
    setObjectName(name);
  }

  const closeImageModal = () => {
    setIsOpen(false);
    setSelectedImage(null);
    setCurrentImageList([]);
  }

  useEffect(() => {
    const snpData = JSON.parse(localStorage.getItem('snp') as string);
    const val = kato ? kato : snpData.kato
    if (val) {
      getProjectList('SNP_AGRICULTURE_PRODUCERS', val)
        .then((res) => {
          setProjectList(transformList(res));
          const obj = res.reduce((acc: any, item: any) => {
            acc[item.id] = res.length < 3;
            return acc;
          }, [])
          setIsListOpen(obj);
        })
        .catch((err) => {
          toast.error('Снп не выбрано');
        })
    }
  }, [])

  useEffect(() => {
    getProjectList('SNP_AGRICULTURE_PRODUCERS', kato)
      .then((res) => {
        setProjectList(transformList(res));
        const obj = res.reduce((acc: any, item: any) => {
          acc[item.id] = res.length < 3;
          return acc;
        }, [])
        setIsListOpen(obj);
      })
      .catch((err) => {
        toast.error('Снп не выбрано');
      })
  }, [kato])

  if (projectList.length === 0) {
    return <p>{t('no-objects-agriculture')}</p>
  }

  return (
    <AgricultureWrapper>
      <div className='list-heading with-icon' onClick={handleOpenAll}>
        {t('list')}
        <ArrowDown width="12" style={{ transform: isAllOpen ? 'rotate(180deg)' : 'rotate(270deg)' }} />
      </div>

      <EducationContainer>
        {projectList.length > 0 && projectList.map((project) =>
          <EducationItem key={project.id}>
            <div className="heading with-icon" onClick={() => handleOpen(project.id)}>
              <ArrowDown width="12" style={{ transform: isAllOpen ? 'rotate(180deg)' : 'rotate(270deg)' }} />
              {project[`name${getLang()}`] || `${t('object-name')} ${project.id}`}
            </div>

            {
              isListOpen[project.id] && <>
                {
                  project[`supervisorName${getLang()}`] && <>
                    <div className="block" key={project.id}>
                      <div className="text">{t('agriculture_producers.supervisorName')}: <b>{capitalize(project[`supervisorName${getLang()}`])}</b></div>
                    </div>
                  </>
                }

                {
                  <div className="block">
                    {project.plantPower && project.plantPower > 0 ? <div className="text">{t('agriculture_producers.plantPower').split(',')[0]}: <b>{project.plantPower} га</b></div> : ''}
                    {project.lifestockPower && project.lifestockPower > 0 ? <div className="text">{t('agriculture_producers.lifestockPower').split(',')[0]}: <b>{project.lifestockPower} {t('lifestock-number')}</b></div> : ''}
                    {project.employeeNumber && project.employeeNumber > 0 ? <div className="text">{t('agriculture_producers.employeeNumber').split(',')[0]}: <b>{project.employeeNumber} {t('form.people-short')}</b></div> : ''}
                    {project.avgSalary && project.avgSalary > 0 ? <div className="text">{t('agriculture_producers.avgSalary').split(',')[0]}: <b> {(formatSalary(project.avgSalary))} {t('tenge')}</b></div> : ''}
                    {project.additionalPowerFields
                      && Object.keys(project?.additionalPowerFields)?.length > 0
                      && Object.keys(project?.additionalPowerFields)?.map(key => <div className="text">{project.additionalPowerFields[key][`label${getLang()}`]}: <b>{project.additionalPowerFields[key][`value${getLang()}`] || project.additionalPowerFields[key].value}</b></div>
                      )}
                  </div>
                }

                {(project.memorandum && project.memorandum.some((item: any) => item.sum && item.sum > 0)) &&
                  <div className="block">
                    <div className="text">{t('agriculture_producers.totalSum').split(',')[0]}:
                      <b> {
                        Math.round(
                          (project.memorandum
                            .filter((item: any) => item.sum && item.sum > 0)
                            .map((item: any) => item.sum)
                            .reduce((a: number, b: number) => +a + +b, 0) + Number.EPSILON) * 100) / 100
                      } млн {t('tenge')}
                      </b>
                    </div>
                    {
                      project[`memorandumTotalPurpose${getLang()}`] && <div className="text">{t('agriculture_producers.totalPurpose').split(',')[0]}
                        <b> {project[`memorandumTotalPurpose${getLang()}`]}</b>
                      </div>
                    }

                    {project.memorandum.map(({ year, sum, labelKz, labelRu }: { year: number, sum: number, labelKz: string, labelRu: string }) =>
                      <div className="memorandum-year">
                        {sum && sum > 0
                          ? <div className="text" key={year}>{year} {t('year')}: <b>{sum} млн {t('tenge')}</b></div> : <></>}
                        {
                          language === 'kz' && labelKz && <div className="text" key={labelKz}>{labelKz}</div>
                        }
                        {
                          language === 'ru' && labelRu && <div className="text" key={labelRu}>{labelRu}</div>
                        }
                      </div>
                    )}
                  </div>
                }

                <AdditionalFields project={project} />

                                {project.images && <ImageGrid images={project.images} openImageModal={openImageModal} name={project[`name${getLang()}`] || `${t('object-name')} ${project.id}`} />}

              </>
            }
          </EducationItem>
        )}
        <ToastContainer />
      </EducationContainer>
      {selectedImage && (
        <ZoomableImageModal
          isFileUpload={true}
          isOpen={isOpen}
          onClose={closeImageModal}
          selectedImage={selectedImage}
          setSelectedImage={setSelectedImage}
          currentImageList={currentImageList}
          objectName={objectName}
        />
      )}
    </AgricultureWrapper >

  )
}

export default AgricultureProducers