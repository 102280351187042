import React from 'react';

import { SelectIcon } from '../../assets/icons';

import { CustomSelectContainer, StyledSelect } from './admin-page.styles';
interface CustomSelectProps {
  options: { value: string; label: string }[];
}

const CustomSelect: React.FC<CustomSelectProps> = ({ options }) => {
  return (
    <>
      <CustomSelectContainer>
        <SelectIcon />
        <StyledSelect disabled>
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </StyledSelect>
      </CustomSelectContainer>
    </>

  );
};

export default CustomSelect;
