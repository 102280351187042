import React, { useEffect, useState } from 'react'
import Street from './street.component';
import { useTranslation } from 'react-i18next';
import { ArrowDown } from '../../assets/icons';

interface IProps {
  streetList: any[];
  openImageModal: (image: any, images: any, name: string) => void;
}

const StreetList: React.FC<IProps> = ({ streetList, openImageModal }) => {
  const { t } = useTranslation()
  const [isAllOpen, setIsAllOpen] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<{ [key: string]: boolean }>({});

  const handleOpen = (id: any) => {
    setIsOpen(prev => ({ ...prev, [id]: !prev[id] }));
  }

  const handleOpenAll = () => {
    const obj = Object.keys(isOpen).reduce((acc: any, key: any) => {
      acc[key] = !isAllOpen;
      return acc;
    }, {})

    setIsOpen(obj);
  }

  useEffect(() => {
    const obj = streetList.reduce((acc: any, street: any) => {
      acc[street.id] = streetList.length < 3;
      return acc;
    }, {})
    setIsOpen(obj);

    setIsAllOpen(Object.values(obj).every((value: any) => value === true));
  }, [streetList])

  useEffect(() => {
    setIsAllOpen(Object.values(isOpen).every((value: any) => value === true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Object.values(isOpen)])

  return (
    <>
      <div className='street-list-heading with-icon' onClick={handleOpenAll}>
        {t('street-list')}
        <ArrowDown width="12" style={{ transform: isAllOpen ? 'rotate(180deg)' : 'rotate(270deg)' }} />
      </div>

      {
        streetList && streetList.length > 0 && streetList.map((street: any) => (
          Object.keys(street)
            .filter(key => ['id', 'files'].includes(key)).some(key => key !== null)
          && <Street key={street.id}
            isOpen={isOpen[street.id]}
            street={street}
            openImageModal={openImageModal}
            handleOpen={handleOpen}
          />
        ))
      }
    </>
  )
}

export default StreetList