import React from 'react'
import { IProject } from '../../interfaces/snp.interface'
import { useTranslation } from 'react-i18next'
import { checkVal, formatDate, getLang } from '../../utils/helpers.utils'

interface IProps {
  project: IProject;
  showFunds?: boolean
}

const Documentation: React.FC<IProps> = ({ project, showFunds = true }) => {
  const { t, i18n: { language } } = useTranslation();

  return (
    <>
      {(project.documentationStatus || project.requiredFund || project.workStart || project.workEnd) &&
        <div className="text-block">
          {project.documentationStatus && <>
            {project.documentationStatus === 'NOT_HAVE' && <div className="text">{t('documentation-yn')}</div>}
            {project.documentationStatus === 'IN_PROCESS' && <div className="text">{t('documentation-yi')}</div>}
          </>}

          {project.documentationStatus === 'HAVE' && <>
            {project.workStart && <div className="text">{t('form.workStart')}: {' ' + formatDate(project.workStart)}</div>}
            {project.workEnd && <div className="text">{t('form.workEnd')}: {' ' + formatDate(project.workEnd)}</div>}

            {checkVal(project[`requiredFundSource${getLang()}`]) && <div className="text">{t('form.requiredFundSource')}: <b>{project[`requiredFundSource${getLang()}`]}</b></div>}
            {checkVal(project[`requiredFundAmount`]) && <div className="text">{t('form.requiredFundAmount')}: <b>{project[`requiredFundAmount`]}</b></div>}

            {project.requiredFund && Object.keys(project.requiredFund).filter(key => !['ru', 'kz'].includes(key)).length > 0 && showFunds && <div className="block">
              {
                <>
                  <div className="subheading">{t('documentation-additional-funds')}:</div>
                  <div className="items">
                    {
                      Object.keys(project.requiredFund).filter(key => !['ru', 'kz'].includes(key)).map(key => (
                        <div className="item">
                          <div className="text" key={key}>{project.requiredFund[key][`label${getLang()}`]} {project.requiredFund[key].value && project.requiredFund?.[key].value !== '0' && `: ${project.requiredFund[key].value} млн ${t('tenge')}`}</div>
                        </div>
                      ))
                    }
                  </div>

                </>
              }
            </div>}
          </>}
        </div>
      }
    </>
  )
}

export default Documentation
