import React, { ChangeEvent, useState } from 'react'
import { getImageUrl } from '../right-menu/education.component';
import Modal from '../modal-component';

import { AddPhotoButton, FooterButton } from '../admin-page/admin-page.styles'
import { FormBlockButton } from './infrastructure.styles'

import { AddField, Pen } from '../../assets/icons';
import { deleteImage, handleEditImage, uploadInfrastructureImage, uploadStreetImage } from '../../requests/supervisor.request';
import { FileDateType, IProject } from '../../interfaces/snp.interface';
import { ToastContainer, toast } from 'react-toastify';
import { formatDate } from '../../utils/helpers.utils';
import { t } from '../../utils/helpers.utils'

interface IProps {
  formData: IProject;
  loadForm: (objectId: number) => void;
  type: string;
  images: any;
  lang: string;
}

const ImageGrid: React.FC<IProps> = ({ formData, loadForm, type, images, lang }) => {

  const [isPhotoModalOpen, setIsPhotoModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [selectedImageName, setSelectedImageName] = useState('');
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [uploadType, setUploadType] = useState<FileDateType | ''>('');
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [imageIdToEdit, setImageIdToEdit] = useState<number | null>(null);

  const openPhotoModal = () => {
    setIsPhotoModalOpen(true);
  };

  const closePhotoModal = () => {
    setIsPhotoModalOpen(false);
  };

  const handleImageSelect = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      const fileName = file.name || '';
      setSelectedImageName(fileName);
      setSelectedImage(file);
    }
  };

  const handleImageUpload = async () => {
    if (!selectedImage) {
      toast.error(t('choose-file', lang));
      return;
    }

    if (!uploadType) {
      toast.error(t('choose-upload-type', lang));
      return;
    }

    const date = selectedDate && new Date(selectedDate);

    if ((date && date.getFullYear() > 2030) || (date && date.getFullYear().toString().length !== 4)) {
      toast.error(t('incorrect-date', lang));
      return;
    }

    if (!selectedDate) {
      toast.error(t('choose-date', lang));
      return;
    }

    const uploadRequest = ['village_road_street', 'lighting_street'].includes(type.toLowerCase()) ? uploadStreetImage : uploadInfrastructureImage;

    date && uploadRequest(formData.kato, formData.id, type, date, uploadType as FileDateType, selectedImage)
      .then(_ => {
        loadForm(formData.id);
        closePhotoModal();
      })
      .catch(() => {
        toast.error(t(`errors.photoResolution4`));
        return
      })

  };

  const handleDeleteImage = () => {
    imageIdToEdit && deleteImage(imageIdToEdit).then(_ => {
      loadForm(formData.id)
      setImageIdToEdit(null);
      setSelectedDate(null);
      setUploadType('');
      setIsDeleteModalOpen(false);
    })
  }

  const handleOpenEdit = (imageId: number) => {
    const current = images['IMAGE_CURRENT']?.find((item: any) => item.id === imageId);
    const after = images['IMAGE_AFTER']?.find((item: any) => item.id === imageId);
    const foundImage = current || after;

    if (foundImage) {
      setSelectedDate(foundImage.date.split('T')[0]);
      setUploadType(foundImage.fileType);
      setImageIdToEdit(imageId);
      setIsEditModalOpen(true);
    }
  }

  const handleCloseEdit = () => {
    setImageIdToEdit(null);
    setSelectedDate(null);
    setUploadType('');
    setIsEditModalOpen(false);
  }

  const handleImageEdit = () => {
    const date = selectedDate && new Date(selectedDate);

    if ((date && date.getFullYear() > 2030) || (date && date.getFullYear().toString().length !== 4)) {
      toast.error('Некорректная дата');
      return;
    }

    if (!selectedDate) {
      toast.error('Выберите дату');
      return;
    }

    if (imageIdToEdit && selectedDate && uploadType) {
      handleEditImage({
        fileId: imageIdToEdit,
        date: new Date(selectedDate).toDateString(),
        fileType: uploadType
      })
        .then((_) => {
          loadForm(formData.id)
          setImageIdToEdit(null);
          setSelectedDate(null);
          setUploadType('');
          setIsEditModalOpen(false);
        })
        .catch(() => {
          toast.error(t(`errors.photoResolution4`));
          return
        })
    }
  }

  return (
    <>
      <div className='mb-4'>
        <AddPhotoButton>
          <FormBlockButton onClick={openPhotoModal}>
            <AddField /> {t('form.add_photo', lang)}
          </FormBlockButton>
        </AddPhotoButton>
        {
          images && Object.keys(images) && Object.keys(images).length > 0 && Object.keys(images).map((key: any) => (
            <>
              <div className='images-row-title' key={key}>{t(key, lang)}</div>
              <div className='images-grid' key={key}>
                {
                  images[key].map((image: any) => (
                    <div className="images-grid__item" key={image.id}>
                      <div className='image-item' onClick={() => handleOpenEdit(image.id)} key={image.id} >
                        <img src={getImageUrl(image.id)} alt="" />
                        <div className="overlay">
                          <Pen stroke='#3399ff' />
                          {t('edit', lang)}
                        </div>
                      </div>
                      <div className='image-date'>{formatDate(image.date)}</div>
                    </div>
                  ))
                }
              </div>
            </>
          ))
        }
      </div>

      <Modal isFileUpload isOpen={isPhotoModalOpen} onClose={closePhotoModal}>
        <label className="input-file">
          <span className="input-file-text">{selectedImageName}</span>
          <input type="file"
            accept="image/*"
            id="imageInput"
            onChange={handleImageSelect} />
          <span className="input-file-btn">{t('choose-file', lang)}</span>
        </label>
        <select name="uploadType" id="uploadType" value={uploadType} onChange={(e) => setUploadType(e.target.value as FileDateType)}>
          <option value="" selected hidden></option>
          {
            ['IMAGE_AFTER', 'IMAGE_CURRENT'].map((type) => (
              <option value={type}>{t(type, lang)}</option>
            ))
          }
        </select>
        <input type="date" onChange={(e) => setSelectedDate(e.target.value)} max={'2030-01-01'} />

        <button className="upload-button" onClick={handleImageUpload} type='button'>
          {t('form.photo.upload', lang)}
        </button>
      </Modal>

      <Modal isOpen={isDeleteModalOpen} onClose={() => setIsDeleteModalOpen(false)}>
        <div className="deleteModalContainer">
          <div>{t('delete-photo', lang)}</div>
          <div className="buttons-container">
            <FooterButton variant='delete' onClick={handleDeleteImage}> {t('delete', lang)}</FooterButton>
            <FooterButton variant='cancel' onClick={() => setIsDeleteModalOpen(false)}> {t('keep', lang)}</FooterButton>
          </div>
        </div>
      </Modal>

      <Modal isOpen={isEditModalOpen} onClose={handleCloseEdit}>
        <select name="uploadType" id="uploadType" value={uploadType} onChange={(e) => setUploadType(e.target.value as FileDateType)}>
          <option value="" selected hidden></option>
          {
            ['IMAGE_AFTER', 'IMAGE_CURRENT'].map((type) => (
              <option value={type}>{t(type, lang)}</option>
            ))
          }
        </select>

        <input type="date" onChange={(e) => setSelectedDate(e.target.value)} max={'2030-01-01'} value={selectedDate as string} />

        <div className="buttons-container">
          <FooterButton onClick={handleImageEdit} className='upload-button' type='button' variant='save'>
            {t('save', lang)}
          </FooterButton>

          <FooterButton onClick={handleCloseEdit} type='button' variant='cancel'>
            {t('cancel', lang)}
          </FooterButton>

          <FooterButton
            onClick={() => {
              setIsEditModalOpen(false)
              setIsDeleteModalOpen(true)
            }}
            type='button'
            variant='delete'
          >
            {t('delete', lang)}
          </FooterButton>
        </div>

      </Modal>

      <ToastContainer />

    </>
  )
}

export default ImageGrid